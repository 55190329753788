import { useState, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import GridContainer from '../layout/GridContainer';
import GridItem from '../layout/GridItem';

import Chart from './chart/Chart';

import RESOURCES from '../../../i18n/Resources';
import TEAMSIGHT from '../../model/TeamSight';
import { MEASUREMENT_TYPE } from '../../model/TeamSight';

/**
 * Displays TeamSight summary information
 * @param {object} props The component properties
 * @returns Components to display TeamSight summary information
 */
const TeamSightSummaryComponent = (props) => {
  const { expanded, expandedValue, setExpanded, title, details } = props;
  return (
    <Accordion
      expanded={expanded === expandedValue}
      onChange={() => {
        setExpanded(expandedValue);
      }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography color="text.secondary">{details}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

/**
 * Component used to view the TeamSight results
 * @param {object} props Component properties
 * @returns Components to display the TeamSight results
 */
export default function TeamSightResultsComponent(props) {
  const timeoutId = useRef(-1);
  const [showChart, setShowChart] = useState(true);
  const [chartId, setChartId] = useState(0);
  const [expanded, setExpanded] = useState(MEASUREMENT_TYPE.IDEA);

  // The cached results
  const stats = TEAMSIGHT.getCachedStats();

  useEffect(() => {
    const resizeListener = () => {
      setShowChart(false);
      if (timeoutId.current !== -1) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(() => {
        setChartId(Date.now());
        setShowChart(true);
      }, 200);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const summaries = [];
  summaries.push(
    <TeamSightSummaryComponent
      expandedValue={MEASUREMENT_TYPE.IDEA}
      expanded={expanded}
      setExpanded={setExpanded}
      key="idea"
      title={RESOURCES.get('text.ideas')}
      details={TEAMSIGHT.getSummaryContent(MEASUREMENT_TYPE.IDEA)}
    />,
  );
  summaries.push(
    <TeamSightSummaryComponent
      expandedValue={MEASUREMENT_TYPE.RELATIONAL}
      expanded={expanded}
      setExpanded={setExpanded}
      key="relational"
      title={RESOURCES.get('text.relational')}
      details={TEAMSIGHT.getSummaryContent(MEASUREMENT_TYPE.RELATIONAL)}
    />,
  );
  summaries.push(
    <TeamSightSummaryComponent
      expandedValue={MEASUREMENT_TYPE.ACTION}
      expanded={expanded}
      setExpanded={setExpanded}
      key="action"
      title={RESOURCES.get('text.action')}
      details={TEAMSIGHT.getSummaryContent(MEASUREMENT_TYPE.ACTION)}
    />,
  );
  summaries.push(
    <TeamSightSummaryComponent
      expandedValue={MEASUREMENT_TYPE.ORDER}
      expanded={expanded}
      setExpanded={setExpanded}
      key="order"
      title={RESOURCES.get('text.order')}
      details={TEAMSIGHT.getSummaryContent(MEASUREMENT_TYPE.ORDER)}
    />,
  );

  return (
    <Box>
      <GridContainer sx={{ mb: 3.5, ...props.sx }}>
        <GridItem>
          <Typography color="text.secondary">
            {RESOURCES.get('text.resultsStatement')}
          </Typography>
        </GridItem>
      </GridContainer>
      <Box sx={{ mx: 1, overflow: 'auto' }}>
        <Chart key={chartId} visible={showChart} stats={stats} />
      </Box>
      <Box sx={{ mt: 4, mx: 1.5 }}>{summaries}</Box>
    </Box>
  );
}

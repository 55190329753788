import { useEffect, useState } from 'react';
import { useForceUpdate } from '../Util';

import Box from '@mui/material/Box';

import CommonButton from '../components/fields/CommonButton';
import CommonFade from '../components/misc/CommonFade';
import CommonLink from '../components/misc/CommonLink';
import CommonPasswordField from '../components/fields/CommonPasswordField';
import CommonTextField from '../components/fields/CommonTextField';
import GridContainer from '../components/layout/GridContainer';
import GridItem from '../components/layout/GridItem';
import ScreenContext from '../components/screen/ScreenContext';
import StandardScreen from '../components/screen/StandardScreen';
import PeopleImage from '../components/images/PeopleImage';

import APP from '../model/App';
import CONFIG from '../../config/Config';
import RESOURCES from '../../i18n/Resources';
import USER from '../model/User';
import { SCREEN_NAMES } from '../Screens';

/**
 * Renders the login screen
 * @returns The login screen content
 */
export default function LoginScreen() {
  // The screen context
  const [screenContext] = useState(new ScreenContext(useForceUpdate()));
  // Field validator
  const { validator } = screenContext;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(true);

  useEffect(() => {
    // TODO: the check methods should probably return true if the
    // state has changed. That would be the indicator to force a
    // refresh.
    validator.checkValidEmail('email', email);
    validator.checkMinLength('password', password);
    screenContext.forceRefresh();
  }, [email, password, screenContext, validator]);

  const isEmailValid = validator.isValid('email');
  const isPasswordValid = validator.isValid('password');

  const isCreateUserAvailable = CONFIG.get('enableCreateUserScreen');

  /**
   * Performs the login operation
   */
  const onSubmit = () => {
    validator.setEnabled(true);
    if (!validator.isValidForAll()) {
      screenContext.forceRefresh();
      return;
    }

    setSubmitEnabled(false);
    setTimeout(async () => {
      try {
        // Attempt login
        APP.showBusyScreen();
        await USER.login(email, password);
      } catch (e) {
        // Show error message
        APP.showExceptionMessage(e);
        setSubmitEnabled(true);
      } finally {
        APP.hideBusyScreen();
      }
    }, 0);
  };

  return (
    <StandardScreen screenContext={screenContext} boxSx={{ pt: 12 }}>
      <CommonFade fadeIn={true}>
        <Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <GridContainer>
              <GridItem>
                <CommonTextField
                  required
                  label={RESOURCES.get('text.email')}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  error={!isEmailValid}
                  helperText={
                    !isEmailValid ? RESOURCES.get('text.emailRequired') : null
                  }
                />
              </GridItem>
              <GridItem>
                <CommonPasswordField
                  required
                  label={RESOURCES.get('text.password')}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                  error={!isPasswordValid}
                  helperText={
                    !isPasswordValid
                      ? RESOURCES.get('text.passwordRequired')
                      : null
                  }
                />
              </GridItem>
              <GridItem>
                <CommonButton
                  type="submit"
                  sx={{ m: 2 }}
                  disabled={!submitEnabled || !isPasswordValid || !isEmailValid}
                  onClick={() => onSubmit()}
                >
                  {RESOURCES.get('text.signIn')}
                </CommonButton>
              </GridItem>
              <GridItem>
                <CommonLink
                  onClick={() => {
                    APP.setScreen(SCREEN_NAMES.RESET_PASSWORD);
                  }}
                >
                  {RESOURCES.get('text.forgotPassword')}
                </CommonLink>
              </GridItem>
              {isCreateUserAvailable && (
                <GridItem>
                  <CommonLink
                    onClick={() => {
                      APP.setScreen(SCREEN_NAMES.REGISTER);
                    }}
                  >
                    {RESOURCES.get('text.needToRegister')}
                  </CommonLink>
                </GridItem>
              )}
              <GridItem>
                <PeopleImage />
              </GridItem>
            </GridContainer>
          </form>
        </Box>
      </CommonFade>
    </StandardScreen>
  );
}

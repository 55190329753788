import * as React from 'react';
import Screen from './Screen';
import StandardScreenHeader from './StandardScreenHeader';

/**
 * The standard screen used throughout the application
 * @param {object} props Component properties
 * @returns The screen content
 */
export default function StandardScreen(props) {
  const { headerContent, ...other } = props;

  const header = (
    <StandardScreenHeader title={props.title} headerContent={headerContent} />
  );

  return (
    <Screen screenContext={props.screenContext} header={header} {...other}>
      {props.children}
    </Screen>
  );
}

import { useState, useEffect } from 'react';

import './style.scss';

import bb from 'billboard.js';
import chartConfig, { configColors, percentileToScale } from './ChartConfig';
import chartDescriptions from './ChartDescriptions';

/**
 * Component to display the TeamSight chart
 * @param {object} props Component properties
 * @returns The TeamSight chart
 */
export default function Chart(props) {
  const { stats, visible } = props;
  const [loaded, setLoaded] = useState(false);

  const users = [];
  const shadows = true;
  const colors = configColors;

  const user = {
    id: 1,
    resultNumber: stats.resultNumber,
    userEmail: 'user@user.com',
    activeAssessment: {
      loaded: true,
      status: 'FINISHED',
      ideasPercentile: stats.ideasPercentile,
      relationalPercentile: stats.relationalPercentile,
      actionPercentile: stats.actionPercentile,
      orderPercentile: stats.orderPercentile,
    },
  };
  users.push(user);

  const graphUsers = () => {
    // translation of user objects to arrays
    return users
      .filter((user) => {
        return (
          user.activeAssessment?.loaded &&
          user.activeAssessment?.status === 'FINISHED'
        );
      })
      .map((user) => {
        return [
          user.id,
          user.activeAssessment.ideasPercentile,
          user.activeAssessment.relationalPercentile,
          user.activeAssessment.actionPercentile,
          user.activeAssessment.orderPercentile,
        ];
      });
  };

  const graphNames = () => {
    return users.reduce((map, user) => {
      map[user.id] = user.userEmail;
      return map;
    }, {});
  };

  const userShadows = () => {
    return graphUsers().reduce((regions, user) => {
      regions.push({
        axis: 'y',
        start: Math.max(user[1] - 15, 0),
        end: Math.min(user[1] + 15, 99),
        class: 'ideas',
      });
      regions.push({
        axis: 'y',
        start: Math.max(user[2] - 15, 0),
        end: Math.min(user[2] + 15, 99),
        class: 'relational',
      });
      regions.push({
        axis: 'y',
        start: Math.max(user[3] - 15, 0),
        end: Math.min(user[3] + 15, 99),
        class: 'action',
      });
      regions.push({
        axis: 'y',
        start: Math.max(user[4] - 15, 0),
        end: Math.min(user[4] + 15, 99),
        class: 'order',
      });
      return regions;
    }, []);
  };

  const lineDescriptions = () => {
    return chartDescriptions.rows.map((measurement) => {
      return {
        value: measurement.minPercentile,
        text: measurement.styleTitle,
        position: 'start',
        class: `bb-label ${measurement.measurement.toLowerCase()}`,
      };
    });
  };

  const measures = ['IDEAS', 'RELATIONAL', 'ACTION', 'ORDER'];

  const tooltipContents = ([data]) => {
    const insight = chartDescriptions.rows.find((measure) => {
      return (
        measure.scale === percentileToScale(data.value) &&
        measure.measurement === measures[data.x]
      );
    });

    const body = `
    <div class="ts-chart-tooltip">
      <div class="ts-chart-tooltip-title">
        <span class="ts-chart-tooltip-score">${percentileToScale(
          data.value,
        )}:</span>${insight?.styleTitle}
      </div>
      <p class="ts-chart-tooltip-body">${insight.style}</p>
    </div>`;

    // Create a clone tooltip that we can control
    const parent = document.getElementById('chart');
    let tsTooltip = document.getElementById('tsTooltip');
    if (!tsTooltip) {
      tsTooltip = document.createElement('div');
      tsTooltip.setAttribute('id', 'tsTooltip');
      tsTooltip.className = 'tsTooltip';
      parent.appendChild(tsTooltip);
    }
    tsTooltip.innerHTML = body;

    return body;
  };

  // loads users and shadows into chart
  function loadChart(chart) {
    const gUsers = graphUsers();

    chart.load({
      unload: true,
      columns: gUsers,
      names: graphNames(),
      done: () => {
        // bad way to deal with seeing animations
        setTimeout(() => {
          setLoaded(true);
        }, 300);
      },
    });
    if (shadows) {
      chart.regions.remove();
      chart.regions.add(userShadows());
    }
  }

  useEffect(() => {
    // one time chart configs set at mounted
    chartConfig.tooltip.contents = tooltipContents;
    chartConfig.data.color = (color, data) => {
      const index = graphUsers().findIndex((u) => u[0] === parseInt(data.id));
      return colors[index];
    };
    chartConfig.data.labels = {
      format: (v) => percentileToScale(v),
    };

    const parent = document.getElementById('chart');
    if (parent) {
      const style = window.getComputedStyle(parent, null);
      const width = style.getPropertyValue('width');
      chartConfig.size = { width: width.substring(0, width.length - 2) };
    }

    const chart = bb.generate(chartConfig);
    chart.ygrids.add(lineDescriptions()); // add grids with descriptions
    loadChart(chart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ minWidth: 750 }} className="ts-chart">
      <table className="ts-chart-header">
        <tbody>
          <tr className="ts-chart-header-titles">
            <td width="25%" className="ideas--text">
              Ideas
            </td>
            <td width="25%" className="relational--text">
              Relational
            </td>
            <td width="25%" className="action--text">
              Action
            </td>
            <td width="25%" className="order--text">
              Order
            </td>
          </tr>
          <tr>
            <td className="ideas--text">How you process and express ideas</td>
            <td className="relational--text">
              How you engage with emotions from others and yourself
            </td>
            <td className="action--text">
              How you assert your force of will on objectives
            </td>
            <td className="order--text">How you structure and complete work</td>
          </tr>
        </tbody>
      </table>
      <div style={{ paddingTop: '16px' }}></div>
      <div style={{ opacity: visible && loaded ? 1 : 0 }} id="chart"></div>
    </div>
  );
}

import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';

import { Screens, SCREEN_NAMES } from './Screens';
import { LOGGER } from '../util/Logging';
import { DarkTheme } from './theme/Theme';
import { StatusCallback } from './model/BaseModel';
import { isForceResetPassword } from './Util';

import AppSnackbar from './AppSnackbar';
import BusyScreen from './components/screen/BusyScreen';
import ErrorBoundary from './components/misc/ErrorBoundry';
import SplashScreen from './screens/SplashScreen';

import APP from './model/App';
import PROFILE from './model/Profile';
import USER from './model/User';
import SURVEY from './model/Survey';
import TEAMSIGHT from './model/TeamSight';

import './App.css';

import { START_STATES } from './AppStartStates';

// Whether the application has started
let started = false;

// Start time
const startTime = Date.now();

/**
 * Starts the application.
 * Waits to determine if a user is available (based on previous login).
 * @async
 * @param {function} setStartState The set start state function
 */
const onAppStart = async (setStartState) => {
  if (!started) {
    started = true; // Mark that the application is starting
    LOGGER.trace('on application start.');

    const statusCallback = new StatusCallback();
    statusCallback.onShow = (message) => {
      APP.showBusyScreen(message);
    };
    statusCallback.onHide = () => {
      APP.hideBusyScreen();
    };

    // Init services
    APP.init(statusCallback);
    PROFILE.init(statusCallback);
    USER.init(statusCallback);
    SURVEY.init(statusCallback);
    TEAMSIGHT.init(statusCallback);

    USER.waitForToken(async (token) => {
      LOGGER.trace(`Token after wait: ${token}`);

      const wait = 2500 - (Date.now() - startTime);
      setTimeout(
        () => {
          if (isForceResetPassword()) {
            // Display the force reset password page
            APP.setScreen(SCREEN_NAMES.RESET_PASSWORD);
          }
          // Mark the application as started
          setStartState(START_STATES.STARTED);
        },
        wait > 0 ? wait : 0,
      );
    }, 10000 /* Wait up to 10 seconds for token */);
  }
};

/**
 * Renders the main application
 * @returns The main application content
 */
function App() {
  const [theme, setTheme] = React.useState(DarkTheme);
  const [screen, setScreen] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [messageSeverity, setMessageSeverity] = React.useState(null);
  const [startState, setStartState] = React.useState(null);

  // App
  APP._setMessage = setMessage;
  APP._setMessageSeverity = setMessageSeverity;
  APP._screen = screen;
  APP._setScreen = setScreen;
  APP._theme = theme;
  APP._setTheme = setTheme;
  APP._startState = startState;

  // User
  USER._setLoggedIn = setLoggedIn;
  USER._loggedIn = loggedIn;

  React.useEffect(() => {
    if (!startState) {
      setStartState(START_STATES.INIT);
      onAppStart(setStartState);
    }
  }, [startState]);

  // TODO: Loading screen, etc.
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary>
        <BusyScreen />
        <AppSnackbar
          message={message}
          setMessage={setMessage}
          severity={messageSeverity}
        />
        {startState !== START_STATES.STARTED && <SplashScreen />}
        {startState === START_STATES.STARTED && <Screens loggedIn={loggedIn} />}
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;

import BaseService from './BaseService';
import CONFIG from '../config/Config';

/**
 * Service for TeamSight-related services
 */
class TeamSightService extends BaseService {
  /**
   * Returns the specified TeamSight task
   * @async
   * @param {string} token The token
   * @param {string} taskId The task identifier
   * @returns The specified TeamSight task
   */
  async getTask(token, taskId) {
    return await this._fetchPost(token, '/survey/get_task/ts', {
      t_id: taskId,
    });
  }

  /**
   * Calculates the statistics for the specified set of answers
   * @async
   * @param {string} answers The answers
   * @returns The statistics for the specified set of answers
   */
  async calculateStats(answers) {
    let ans = answers.toLowerCase();
    ans = ans.replaceAll('c', 'A');
    ans = ans.replaceAll('b', 'U');
    ans = ans.replaceAll('a', 'D');

    return await this._fetchPost(
      CONFIG.get('teamsight.token'),
      CONFIG.get('teamsight.calculateStatsUrl'),
      {
        answers: ans,
      },
      true,
      'application/json',
      true,
    );
  }
}

// Singleton
const TEAMSIGHT_SERVICE = new TeamSightService();

export default TEAMSIGHT_SERVICE;

import List from '@mui/material/List';

/**
 * A list of selectable items
 * @param {object} props Component properties
 * @returns The selectable items content
 */
export default function SelectionList(props) {
  return (
    <List
      sx={{
        marginX: {
          xs: 2,
          sm: 5,
        },
        backgroundColor: 'background.paper',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'divider',
        borderRadius: 4,
      }}
      {...props.other}
    >
      {props.children}
    </List>
  );
}

const chartDescriptions = {
  count: 20,
  rows: [
    {
      id: 1,
      measurement: 'IDEAS',
      scale: 5,
      minPercentile: 86,
      maxPercentile: 100,
      exceptional:
        '{Name}, you tend to focus on the big picture perspective. You naturally use abstractions, metaphors and story-telling to inspire others with your vision. You gravitate towards new innovative ideas and solutions and believe nearly anything is possible.',
      typical:
        'Generating new ideas for what could work at the broadest level; Tying ideas and projects to the big picture for yourself and others; Seeing all the possibilities; Asking questions as a way to move others toward your vision; Creatively problem solving; Speaking inspirationally and persuasively about your ideas',
      negative:
        "You may become defensive if your ideas are consistently met with questions implying doubt or impracticality - you may feel others are only focusing on what won't work rather than focusing on parts that could work.",
      opportunities:
        'You can apply your experiential learnings from both your professional and personal life; You have the opportunity to express, discuss, and validate ideas verbally with others; You are in an environment that fosters creativity and open collaboration',
      personalNeed:
        'Learns experientially from life;Expresses and discusses ideas verbally;',
      title: 'Innovator',
      style:
        'Focuses on the big picture. Uses abstractions and metaphors to inspire others with your vision.',
      styleTitle: 'What could work',
      percentileDescription:
        'Your result is in the abstract dimensions of the scale, which means you tend to think in terms of principles and values, and speak in summary statements.',
      measurementDescription:
        'The ideas scale measures how you process and express ideas. This scale progresses from the abstract to the concrete.',
    },
    {
      id: 2,
      measurement: 'IDEAS',
      scale: 4,
      minPercentile: 66,
      maxPercentile: 86,
      exceptional:
        '{Name}, you gravitate to the strategy and values of an initiative. You consider the consequences of ideas as you evaluate them for strategic importance and practicality.',
      typical:
        'Critiquing ideas through questions and debate;Contextualizing a vision and translating it into strategy;Calling out practical implications and potential blindspots on large scale initiatives;Understanding how current activities may impact future options or endeavors',
      negative:
        "When someone implies you don't understand an idea, this can be frustrating for you,  when, in fact, you may understand the strategic impact more clearly than the person who introduced it. When someone interprets your critique or healthy debate of ideas as inherently negative, this can be upsetting to you because in reality, you are trying to gain further clarity to push towards the goal.",
      opportunities:
        'You have time to process the implications of ideas and options; You can evaluate ideas for strategic importance and translate into strategy; You feel you are being heard and trusted; You can work in an environment where feedback through debate is not only tolerated, but encouraged',
      personalNeed:
        'To have time to process the implications of options;To be heard and trusted;',
      title: 'Strategist',
      style:
        'Evaluates ideas for strategic importance and practicality. Contextualizes the vision and translates it into strategy.',
      styleTitle: 'Could that work here',
      percentileDescription:
        'Your result is in the abstract dimensions of the scale, which means you tend to think in terms of principles and values, and speak in summary statements.',
      measurementDescription:
        'The ideas scale measures how you process and express ideas. This scale progresses from the abstract to the concrete.',
    },
    {
      id: 4,
      measurement: 'IDEAS',
      scale: 2,
      minPercentile: 16,
      maxPercentile: 36,
      exceptional:
        '{Name}, you tend to prefer processes, standards and resources as you coordinate specific implementation efforts.',
      typical:
        'Identifying the simplest or most expedient solution;Building effective processes around accessible resources;Clarifying what needs to be done next to get things moving;Defining obstacles to implementation and process',
      negative:
        "When someone says we can't do it like that or won't answer your questions trying to gain clarity, you can get frustrated because it feels as though your practical perspective isn't being valued",
      opportunities:
        'You are given the opportunity to organize resources and procedures to guide execution; You are in a position to coordinate efforts and activities during implementation, solving problems along the way; You feel your practical solutions are heard and valued',
      personalNeed:
        'Access to resources that impact decisions;To solve problems in practical ways;',
      title: 'Organizer',
      style:
        'Organizes resources and procedures to guide execution. Coordinates implementation effort and activity, solving problems along the way.',
      styleTitle: 'What we need to make it work',
      percentileDescription:
        'Your result is in the concrete dimensions of the scale, which means you tend to separate ideas into distinct facts, and speak in a detailed narrative.',
      measurementDescription:
        'The ideas scale measures how you process and express ideas. This scale progresses from the abstract to the concrete.',
    },
    {
      id: 5,
      measurement: 'IDEAS',
      scale: 1,
      minPercentile: 0,
      maxPercentile: 16,
      exceptional:
        '{Name}, you prefer thinking about the practical implications of ideas and strive for documentation, procedures and replicapability that help achieve results.',
      typical:
        'Producing data around the ideas being considered;Identifying potential bottlenecks in ideas, strategies, systems, and procedures;Helping teams work most efficiently; Creating and following procedural steps',
      negative:
        'When someone is unwilling or unable to ground their ideas, this may be frustrating for you. A new and creative idea is exciting, but you know all the work that lies ahead to bring it to life.',
      opportunities:
        'You have time to explore the practical implications of an idea to improve execution; You are provided the opportunity to ask questions that consider the long term sustainability of the project; You are provided the opportunity to ask questions that consider the impacts post-launch; You are provided time to collect data and viewpoints, then prioritize them for optimal outcomes',
      personalNeed:
        'Opportunity to ask questions that consider impacts beyond the launch;Time to collect data and opinions, then organize them into efficient systems;',
      title: 'Refiner',
      style:
        'Focuses on the practical implications of an idea to improve the execution plan. Uses logic to create clarity of understanding, and wants to discuss contingencies.',
      styleTitle: 'Is it ready to work yet ',
      percentileDescription:
        'Your result is in the concrete dimensions of the scale, which means you tend to separate ideas into distinct facts, and speak in a detailed narrative.',
      measurementDescription:
        'The ideas scale measures how you process and express ideas. This scale progresses from the abstract to the concrete.',
    },
    {
      id: 6,
      measurement: 'RELATIONAL',
      scale: 5,
      minPercentile: 86,
      maxPercentile: 100,
      exceptional:
        'Connecting with others by recognizing and responding to their emotions is a priority for you. Resolving conflict and nurturing those on your team is a strength.',
      typical:
        'Readily sensing emotional needs in others;Responding in an emotionally nurturing way;Communicating openness and acceptance through actions, not just words;Committing to including others in your network of relationships',
      negative:
        'You may try to manage the negative comments or emotions of others; You may find drawing healthy boundaries for yourself and others challenging',
      opportunities:
        "You can create environments where everyone is aligned; You have time to assure everyone's persepctives are being considered; You can  prioritize resolving conflict and nurturting team members to create positive emotional energy",
      personalNeed:
        'Create environments where everyone is aligned;Time to assure everyone’s perspectives are being considered;',
      title: 'Nurturer',
      style:
        'Focuses on creating positive emotional energy. Prioritizes resolving conflict and nurturing team members.',
      styleTitle: 'I am aware',
      percentileDescription:
        'Your result is in the empathetic dimensions of the scale, which means you tend to experience the emotions of others as if they were your own.',
      measurementDescription:
        'The emotion scale measures how you process and engage with emotion-yours and others. This scale progresses from the empathetic to the insulated.',
    },
    {
      id: 7,
      measurement: 'RELATIONAL',
      scale: 4,
      minPercentile: 66,
      maxPercentile: 86,
      exceptional:
        'Creating a positive environment, including opportunities for others to contribute and collaborate, is important to you. Developing and investing in deep, loyal relationships with team members is a strength.',
      typical:
        'Responding empathically to the emotions of others; Filtering out negative emotions by expressing your own positive emotions; Building and nurturing deep, loyal relationships with other team members; Feeling responsible for creating a positive tone for the team',
      negative:
        'You may shutdown when overloaded from your own emotions or from the emotions of others; You may compromise on our own view or opinion in order to create harmony amongst the team',
      opportunities:
        'You feel encouraged by others and you have the opportunity to respond to other’s ideas positively; You are working in a cohesive and collaborative environment where others feel heard and supported; You are considered a trustworthy confidant and team member; You can proactively position yourself as a “go to” member of the team for others when they need to express their emotions or process an experience',
      personalNeed:
        'Encourage others—and be encouraged by others;To be considered a trustworthy confidant and team member;',
      title: 'Empathizer',
      style:
        'Develops deep, loyal relationships with team members. Feels a responsibility to support others emotionally.',
      styleTitle: 'I connect',
      percentileDescription:
        'Your result is in the empathetic dimensions of the scale, which means you tend to experience the emotions of others as if they were your own.',
      measurementDescription:
        'The emotion scale measures how you process and engage with emotion-yours and others. This scale progresses from the empathetic to the insulated.',
    },
    {
      id: 9,
      measurement: 'RELATIONAL',
      scale: 2,
      minPercentile: 16,
      maxPercentile: 36,
      exceptional:
        'Creating emotional authenticity as you take an objective, straightforward view of situations in a strength.',
      typical:
        'Focusing on the clarity of the issue;Filtering out overly positive responses and driving towards clarity;Creating freedom for others to verbalize their view;Accepting conflict as a component in making decisions',
      negative:
        'You may focus on creating clarity around issues versus responding to the emotions of others. Recognize that the intensity with which you communicate your perspective can catch people off guard.',
      opportunities:
        'You can take an objective, straightforward view of situations. You have the freedom to speak up, verbalize negative observations and practice emotional transparency; You have the opportunity to resolve issues away from emotional pressures and the freedom to speak up',
      personalNeed:
        'Freedom to speak up;To resolve issues apart from emotional pressures;',
      title: 'Verbalizer',
      style:
        'Takes an objective, straightforward view of situations. Verbalizes negative observations and practices emotional transparency.',
      styleTitle: 'I want authentic',
      percentileDescription:
        'Your result is in the insulated dimensions of the scale, which means you tend to experience your own emotions and observe the emotions of others.',
      measurementDescription:
        'The emotion scale measures how you process and engage with emotion-yours and others. This scale progresses from the empathetic to the insulated.',
    },
    {
      id: 10,
      measurement: 'RELATIONAL',
      scale: 1,
      minPercentile: 0,
      maxPercentile: 16,
      exceptional:
        "Evaluating a situation quickly and respond rationally, factually and analytically is a natural strength.  You are aware of and observe emotion responses in others but, you don't allow emotions to derail your course of action.",
      typical:
        'Observing emotion but may not engaging it;Responding to conflict with stoicism or emotional reserve;Looking at issues pragmatically, solving problems;Showing loyalty to people who they deem worthy of it',
      negative:
        "You may not show your feelings and expect others to manage their own. Learn to acknowledge other people's emotions by pausing and validating their perspective.",
      opportunities:
        'You can evaluate a situation quickly and get to the point. You can observe emotional responses of others without allowing them to derail your course of action; You can deal with the facts of the matter, expecting others to take personal responsibility for their emotions',
      personalNeed:
        'Deals with the “facts” of the matter;That people take personal responsibility for their emotions;',
      title: 'Observer',
      style:
        'Evaluates a situation quickly and gets to the point. Observes emotional responses without allowing them to derail your course of action.',
      styleTitle: 'I want rational',
      percentileDescription:
        'Your result is in the insulated dimensions of the scale, which means you tend to experience your own emotions and observe the emotions of others.',
      measurementDescription:
        'The emotion scale measures how you process and engage with emotion-yours and others. This scale progresses from the empathetic to the insulated.',
    },
    {
      id: 11,
      measurement: 'ACTION',
      scale: 5,
      minPercentile: 86,
      maxPercentile: 100,
      exceptional:
        'Action-oriented, this person knows what needs to get done and uses both others and themselves to get it done. You are equally comfortable leading a project but can also deliver on specific tasks. In projects, you identify and prioritize goals and exercise authority where appropriate.',
      typical:
        'Being readily available to jump in;Using charismatic forcefulness to move the team forward;Inspiring others to take initiative; Encouraging scalability; Giving direction to others in the pursuit of a goal',
      negative:
        "When someone indicates you're moving too fast or not listening to others, you can get defensive because you see your vision, ambition, and drive as critical to the team's success.; You may become frustrated if a project is not moving at the pace you envisioned, as you felt you had clearly outlined expectations in the beginning.",
      opportunities:
        'You are able to take on challenges with the necessary tools to succeed; You are given authority to move projects and teams forward; You can focus on self-determination and regulating the process of acheiving goals; You are given the autonomy to move quickly and take on several tasks at once',
      personalNeed:
        'Significant challenges and access to resources;Authority to move projects and teams forward;',
      title: 'Director',
      style:
        'Directs team members and exercises authority, even over those who are not direct reports. Determined and driven.',
      styleTitle: 'Directs projects',
      percentileDescription:
        'Your result is in the assertive dimensions of the scale, which means you tend to focus on goal achievement and look for opportunities.',
      measurementDescription:
        'The action scale measures how you exert your force of will on objectives. This scale progresses from the assertive to the unifying.',
    },
    {
      id: 12,
      measurement: 'ACTION',
      scale: 4,
      minPercentile: 66,
      maxPercentile: 86,
      exceptional:
        'You provide context and explanation in projects. You are influential and are equally comfortable to lead from behind and from the front.',
      typical:
        'Identifying what will make the biggest impact and what will be the next milestone;Demonstrating commitment to the team and organizational goals;Understanding the limits of your authority within the organization;Inspiring others through decisiveness and determination; Providing context and explanation when assigning tasks',
      negative:
        "When someone implies you didn't provide enough context for the task at hand - this can be frustrating for you because you feel you took the time to provide ample explanation for the project to move forward.",
      opportunities:
        'You can operate freely while being mindful of reporting structures; You have motivated, competent people around you who are ready to jump in and take charge; It is recognized that you may be more comfortable leading from behind the scenes than up front',
      personalNeed:
        'A clear reporting structure with freedom to operate;Competent people around you who can execute;',
      title: 'Determined',
      style:
        'Focuses on self-determination and regulating the process of achieving goals. May be more comfortable at times leading behind the scenes than up front.',
      styleTitle: 'Moves people',
      percentileDescription:
        'Your result is in the assertive dimensions of the scale, which means you tend to focus on goal achievement and look for opportunities.',
      measurementDescription:
        'The action scale measures how you exert your force of will on objectives. This scale progresses from the assertive to the unifying.',
    },
    {
      id: 14,
      measurement: 'ACTION',
      scale: 2,
      minPercentile: 16,
      maxPercentile: 36,
      exceptional:
        'You value building process and prediction in tasks. You look to build efficiencies and effectiveness in projects. You prefer to execute towards clear goals.',
      typical:
        "Taking direct action on identified needs that align with your expertise;Inspiring others to perform team tasks;Creating clarity around your role and others';Identifying obstacles and areas that need improvement;",
      negative:
        'When someone implies you lack assertiveness, you can get frustrated because you prefer to suggest what you think is helpful or go with the group, instead of voicing and defending your stance.',
      opportunities:
        'You have a clear understanding of your role and the projects and/or employees you manage; You closely monitor the input and contribution of other highly skilled team members; You share authority in the service of achieving goals',
      personalNeed: 'A clear goal;A clear role;',
      title: 'Responsive',
      style:
        'Aware of the needs of other team members. Takes responsibility for getting the work done.',
      styleTitle: 'Identifies roles',
      percentileDescription:
        'Your result is in the unifying dimensions of the scale, which means you tend to focus on helping others achieve objectives.',
      measurementDescription:
        'The action scale measures how you exert your force of will on objectives. This scale progresses from the assertive to the unifying.',
    },
    {
      id: 15,
      measurement: 'ACTION',
      scale: 1,
      minPercentile: 0,
      maxPercentile: 16,
      exceptional:
        'You tend to seek specific direction. You are enthusiastic about completing tasks once you understand your role and the parameters for success.',
      typical:
        'Acting with expediency to create immediate impact and results;Identifying tasks the team can accomplish within outlined role;Defining the obstacles to forward progress;Pushing yourself to maximize team results; Being an enthusiastic team player',
      negative:
        'When someone suggests you need to take more of a lead and ask fewer questions before jumping in, you can get frustrated because you push yourself to accomplish the goals of the team instead of promoting your own - that requires full understanding of the task at hand.',
      opportunities:
        'You have a clear goal and a clear role in accomplishing the goal; You can embrace and take responsibility for getting work done; You can utilize your knowledge of the tools and systems other team members need to accomodate accordingly',
      personalNeed: 'Consistent leadership;Clear reporting lines;',
      title: 'Ready',
      style:
        'Ready for action and enthusiastic about taking on and owning tasks.',
      styleTitle: 'Completes tasks',
      percentileDescription:
        'Your result is in the unifying dimensions of the scale, which means you tend to focus on helping others achieve objectives.',
      measurementDescription:
        'The action scale measures how you exert your force of will on objectives. This scale progresses from the assertive to the unifying.',
    },
    {
      id: 16,
      measurement: 'ORDER',
      scale: 5,
      minPercentile: 86,
      maxPercentile: 100,
      exceptional:
        'Your natural comfort is when you understand and have control of your environment. You drive precision and detail in everything you do. More detail and explanation is preferred. Predictability is very important.',
      typical:
        'Defining what "complete" looks like and understanding how to achieve it;Maintaining order by hands on management of a system, process, or team;Creating accountability over delegated tasks; Marking a task as done only when all the outlined steps are complete',
      negative:
        "When someone implies you're too controlling, you can get defensive because, while they feel you are trying to control what they do or how they do it, you are working hard to achieve the best result on the project.",
      opportunities:
        'You can monitor activities or tasks that directly affect you; You have predictability and the ability to set up structures that will avoid last minute surprises or unforseen consequences; You have an organized process to ensure all key aspects of a task are accomplished',
      personalNeed: 'Control over activities that affect you;',
      title: 'Controller',
      style:
        'Creates and controls systems and processes to produce detailed, precise, routine work.',
      styleTitle: 'Hands-on completion',
      percentileDescription:
        'Your result is in the routine dimensions of the scale, which means you tend to focus on disciplined, hands-on maintenance of systems and activities.',
      measurementDescription:
        'The order scale measures how you structure and complete work. This scale progresses from the routine to the flexible.',
    },
    {
      id: 17,
      measurement: 'ORDER',
      scale: 4,
      minPercentile: 66,
      maxPercentile: 86,
      exceptional:
        'Your natural ability to create order and manage detail for team members drives alignment and results. Deadlines are critical to you and you worry about delivering everything expected.',
      typical:
        'Emphasizing the use of data and trends to streamline processes; Always looking for ways to troubleshoot systems;Providing structure and a clear process for tasks that can be repeated;Maintaining accountability over the tasks and goals you delegate;',
      negative:
        "When someone says you're nit-picking or hovering, you can get defensive because you pride yourself in the final work product being precise and accurate.",
      opportunities:
        'You can create order and manage detail where needed to deliver results; Communication is clear; There is a shared understanding amongst team members of the end result timeline and objectives; You can monitor progress of delegated tasks',
      personalNeed: 'Clear, results-oriented communication and deadlines;',
      title: 'Driver',
      style:
        'Creates order and manages detail where needed to deliver results.',
      styleTitle: 'Clear structure',
      percentileDescription:
        'Your result is in the routine dimensions of the scale, which means you tend to focus on disciplined, hands-on maintenance of systems and activities.',
      measurementDescription:
        'The order scale measures how you structure and complete work. This scale progresses from the routine to the flexible.',
    },
    {
      id: 19,
      measurement: 'ORDER',
      scale: 2,
      minPercentile: 16,
      maxPercentile: 36,
      exceptional:
        'Completes tasks using the available information at the time but is willing to change the path as new information surfaces. Precision is less important than outcome of a project. Prefers a hands off approach when directing others.',
      typical:
        'Seeing work as a series of projects;Giving team members the opportunity to self-direct their work;Focusing on outcomes over process;Intuitively understanding when a project is overly restrictive and adjusting parameters accordingly',
      negative:
        "When someone suggests you don't finish individual tasks, you can get defensive because you're focused more on the impact of a project than a perfect project, and are ready to move on when you believe impact is realized.",
      opportunities:
        'You are presented with a variety of challanges and opportunities coupled with the flexibility to optimize impact; You are given the autonomy to complete individual tasks on your own schedule within the given project timeline',
      personalNeed:
        'You may find yourself moving a project to completion when you sense that the results are achieved to produce the desired outcome.',
      title: 'Recruiter',
      style:
        'Delegates ongoing efforts to team members. Comfortable balancing multiple projects or tasks. ',
      styleTitle: 'Impact of execution',
      percentileDescription:
        'Your result is in the flexible dimensions of the scale, which means you tend to focus your efforts intensely on projects, then adopt a hands-off attitude when you perceive your part is completed.',
      measurementDescription:
        'The order scale measures how you structure and complete work. This scale progresses from the routine to the flexible.',
    },
    {
      id: 20,
      measurement: 'ORDER',
      scale: 1,
      minPercentile: 0,
      maxPercentile: 16,
      exceptional:
        'Control is not critical in your environment. You are a risk-taker and can be very flexible and go with the flow easily. You prefer high creative freedom environments.',
      typical:
        'Completing projects by thinking outside the box and changing the rules;Identifying the need for creative freedom or attention to certain details;Creating a free-spirited environment where experimenting is encouraged;Seeking to move on once the impact of the task has been achieved; Steering away from predictable, routine work;',
      negative:
        "When someone implies you procrastinate or don't structure your work, you can get defensive because, from your perspective, you always get the job done, it just may not be according to the structured schedule someone else may expect or prefer.",
      opportunities:
        'You are free from restrictive processes and can work in an environment where changes are welcome; You can delegate and create freedom for team members to execute on tasks',
      personalNeed:
        'Freedom from restrictive processes;Iterative environments where changes are welcome;',
      title: 'Delegator',
      style:
        'Delegates and creates freedom for team members to produce their unique personal craftsmanship.',
      styleTitle: 'Speed of execution',
      percentileDescription:
        'Your result is in the flexible dimensions of the scale, which means you tend to focus your efforts intensely on projects, then adopt a hands-off attitude when you perceive your part is completed.',
      measurementDescription:
        'The order scale measures how you structure and complete work. This scale progresses from the routine to the flexible.',
    },
    {
      id: 21,
      measurement: 'IDEAS',
      scale: 3,
      minPercentile: 36,
      maxPercentile: 50,
      exceptional:
        '{Name}, you prefer clarity around the cause and effect of decisions and balance the options to build systems that bring the vision to life.',
      typical:
        'Quickly understanding systems for the work your team is doing;Increasing organizational effectiveness through structures and tools;Designing systems, processes, and workable solutions;Integrating systems across teams and organizations',
      negative:
        "When you're trying to facilitate conversations between those creating ideas and those implementing, it can be frustrating for you when there is a resistance towards compromise. It can be overwhelming when others won't try to see both sides or dismiss you for trying to get them to.",
      opportunities:
        'You are provided time to connect all the dots; You are able to develop and integrate systems to execute the strategy to bring the vision to life; You have access to decisions makers to clarify options; You feel all parties are working towards a common goal and are willing to see where to compromise to achieve the end result',
      personalNeed:
        'Time to connect all the dots and build a workable system;Access to decision makers to clarify options;',
      title: 'Planner',
      style:
        'Develops and integrates the system to execute the strategy and bring the vision to life. Mediates between team members at opposite ends of the scale.',
      styleTitle: 'How that would work here',
      percentileDescription:
        'Your result is in the concrete dimensions of the scale, which means you tend to separate ideas into distinct facts, and speak in a detailed narrative.',
      measurementDescription:
        'The ideas scale measures how you process and express ideas. This scale progresses from the abstract to the concrete.',
    },
    {
      id: 22,
      measurement: 'RELATIONAL',
      scale: 3,
      minPercentile: 36,
      maxPercentile: 50,
      exceptional:
        'Balancing awareness of emotion, you own emotion and the emotion of others, with rational analysis comes naturally to you. You help negoitate conflicts by surfacting all perspectives.',
      typical:
        'Sensing situations that are out of balance and trying to add the opposite emotional perspective;Expressing a neutral level of emotion for different situations;Balancing the emotional and the rational;Valueing and striving for fairness for all',
      negative:
        'You may create balance by taking the opposite emotional perspective when you sense a situation is out of balance; You mat attempt to surface the different perspectives in a room, not allowing any one emotion to dominate.',
      opportunities:
        'You can manage the positive and negative emotions of others to strengthen connections and build trust; You can navigating conflict by surfacing all perspectives; You can ensure that every team member is being heard and treated fairly, yourself included. You are listened to when emotions are taking over',
      personalNeed:
        'Ensure that every team member is being heard and treated fairly, yourself included;To be listened to when emotions are taking over;',
      title: 'Negotiator',
      style:
        'Negotiates conflicts by surfacing all perspectives. Manages positive and negative emotions of others to strengthen connections and build trust.',
      styleTitle: 'I want fair',
      percentileDescription:
        'Your result is in the insulated dimensions of the scale, which means you tend to experience your own emotions and observe the emotions of others.',
      measurementDescription:
        'The emotion scale measures how you process and engage with emotion-yours and others. This scale progresses from the empathetic to the insulated.',
    },
    {
      id: 23,
      measurement: 'ACTION',
      scale: 3,
      minPercentile: 36,
      maxPercentile: 50,
      exceptional:
        "You comfortably share authority in the service of achieving goals. You thrive when you have defined project responsibilities and understand how each person's contributions work towards the common goal.",
      typical:
        "Coordinating efforts and defining project time limits;Defining the project rules and their relative flexibility;Identifying the most expedient, and realistic, goals; Recognizing how others' contributions work towards the common goal",
      negative:
        "When asking for clarity, others may imply you are getting too caught up in the details by asking too many questions. If you don't have a high level understanding of what you're doing or why you're doing it, that's very uncomfortable.",
      opportunities:
        "You are a part of, or at least have visibility into what went into the planning process; You are able to ask clarifying questions to comprehend the intended impact; You understand how others' roles are contributing to the end result",
      personalNeed:
        'Access to the entire system/process affecting your work;Authority to direct teams toward coordination of effort;',
      title: 'Manager',
      style:
        'Manages the input and contribution of other highly skilled team members. Shares authority in the service of achieving goals.',
      styleTitle: 'Coordinates effort',
      percentileDescription:
        'Your result is in the unifying dimensions of the scale, which means you tend to focus on helping others achieve objectives.',
      measurementDescription:
        'The action scale measures how you exert your force of will on objectives. This scale progresses from the assertive to the unifying.',
    },
    {
      id: 24,
      measurement: 'ORDER',
      scale: 3,
      minPercentile: 36,
      maxPercentile: 50,
      exceptional:
        'You balance priorities and objectives equally to deliver results. You are comfortable delegating but when necessary can take a hands on approach. You are risk taking but conservative about how far you go.',
      typical:
        'Creating and maintaining order for yourself and team members;Easily shifting between routine work and project based work;Delegating tasks while maintaining some level of oversight; Developing and managing systems that track and measure activity progress',
      negative:
        "When someone implies you change direction too quickly, this can frustrate you as you are comfortable adapting to changing priorities.; When someone suggests you're spending too much time in the details, you can get defensive because you focus on creating systems that measure progress to streamline delivering the final project.",
      opportunities:
        'You have the freedom to create efficient systems and drive clear accountabilities; You have the autonomy to make in-the-moment adjustments based on new information',
      personalNeed:
        'Freedom to create efficient systems and set up clear accountabilities;Competent people who can execute;',
      title: 'Juggler',
      style:
        'Considers adjusting objectives within a project based on prioritization or expediency. Develops review procedures to track results.',
      styleTitle: 'Available trade-offs',
      percentileDescription:
        'Your result is in the flexible dimensions of the scale, which means you tend to focus your efforts intensely on projects, then adopt a hands-off attitude when you perceive your part is completed.',
      measurementDescription:
        'The order scale measures how you structure and complete work. This scale progresses from the routine to the flexible.',
    },
  ],
};

export default chartDescriptions;

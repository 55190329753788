import { useState } from 'react';

import Avatar from '@mui/material/Avatar';

import PROFILE from '../../model/Profile';

/**
 * Returns the color to use for the particular name
 * @param {string} string The name
 * @returns The color to use for the name
 */
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

/**
 * Returns the content for the string version of the avatar
 * @param {object} sx Style properties
 * @param {function} textCallback Optional callback to render text
 * @param {string} name The name to convert to a string avatar
 * @param {boolean} loaded Whether the avatar is loaded
 * @returns The content for the string version of the avatar
 */
function stringAvatar(sx, textCallback, name, loaded) {
  if (!sx) sx = {};
  let initials = null;

  if (name) {
    name = name.toUpperCase();
    const parts = name.split(' ');
    if (parts.length > 1) {
      initials = `${parts[0][0]}${parts[1][0]}`;
    } else if (parts.length > 0) {
      initials = `${parts[0][0]}`;
    }
  }

  const vis = {
    visibility: loaded ? 'visible' : 'hidden',
  };

  if (initials) {
    return {
      sx: {
        ...sx,
        ...vis,
        bgcolor: stringToColor(name),
      },
      children: textCallback ? textCallback(initials) : initials,
    };
  } else {
    return {
      sx: {
        ...sx,
        ...vis,
      },
    };
  }
}

/**
 * Component used to render the logged in user's avatar
 * @param {object} props Component properties
 * @returns The avatar content
 */
export default function CommonAvatar(props) {
  const { sx, textCallback, ...other } = props;
  const [loaded, setLoaded] = useState(false);

  let sa = {};
  sa = stringAvatar(sx, textCallback, PROFILE.getName(), !props.src || loaded);

  return (
    <Avatar
      onLoad={() => {
        setLoaded(true);
      }}
      {...sa}
      {...other}
    ></Avatar>
  );
}

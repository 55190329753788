import { useEffect } from 'react';

import Box from '@mui/material/Box';
import CommonFade from '../../components/misc/CommonFade';
import IntroComponent from './components/IntroComponent';

import introImage1Src from '../../../assets/images/introImage1.jpg';
import introImage2Src from '../../../assets/images/introImage2.png';
import relaxSrc from '../../../assets/images/relax.jpg';
import orderingSrc from '../../../assets/images/ordering.gif';
import sliderSrc from '../../../assets/images/slider.gif';
import zoomSrc from '../../../assets/images/zoom.gif';

/**
 * Intro slider scale page
 */
const SliderScaleContent = (props) => {
  return (
    <IntroComponent
      title={'Tip #2: Slider Scale Questions'}
      imageSrc={sliderSrc}
      lines={[
        'Some questions include a response slider.',
        'You can select a pre-determined answer, or use the slider to adjust your ' +
          "response when there isn't a direct match (demonstrated above).",
      ]}
    />
  );
};

/**
 * Intro zoom page
 */
const ZoomContent = (props) => {
  return (
    <IntroComponent
      title={'Tip #1: Image Zooming'}
      imageSrc={zoomSrc}
      lines={[
        'Before beginning the questions, we will walk through a series of useful tips.',
        'The first tip is that all images associated with a question can be zoomed by tapping or clicking on them (demonstrated above).',
      ]}
    />
  );
};

/**
 * Intro ordering page
 */
const OrderingContent = (props) => {
  return (
    <IntroComponent
      title={'Tip #3: Ordering Questions'}
      imageSrc={orderingSrc}
      lines={[
        'Some questions expect answers to be ordered.',
        'Tap and hold (or click and drag) an answer to place it at the desired location (demonstrated above).',
      ]}
    />
  );
};

/**
 * Intro step 1 content
 */
const PreStart1Content = (props) => {
  return (
    <IntroComponent
      title={'Overview'}
      imageSrc={introImage1Src}
      imageBorder={false}
      lines={[
        'You will now be presented with a series of questions designed to determine your professional traits. There are no right or wrong answers, the questions are designed to gain insights that uniquely describe you.',
      ]}
    />
  );
};

/**
 * Intro step 2 content
 */
const PreStart2Content = (props) => {
  return (
    <IntroComponent
      title={'Overview'}
      imageSrc={introImage2Src}
      imageBorder={false}
      lines={[
        "At the conclusion of the questions, you will be presented with a visual and written analysis of your professional traits. These results include a common vocabulary, providing insights into coworkers' perceptions of you, and how to best leverage your strengths when interacting with others.",
      ]}
    />
  );
};

/**
 * Intro step 3 content
 */
const PreStart3Content = (props) => {
  return (
    <IntroComponent
      title={'Ready... Set... Relax...'}
      imageSrc={relaxSrc}
      imageBorder={false}
      lines={[
        'The questions are about to start.',
        'Please relax and take your time to thoughtfully and honestly answer the questions to ensure the accuracy of your results.',
        'There are approximately 250 questions that can be answered all at once or over multiple sessions (you can safely leave and resume).',
      ]}
    />
  );
};

/**
 * Displays the pre questions task
 * @returns The pre questions content
 */
export default function PreQuestionsTask(props) {
  const { setAnswer, subtask } = props;
  const statement = subtask.statement;

  let content = null;
  if (statement === 'step 1') {
    content = <PreStart1Content />;
  } else if (statement === 'step 2') {
    content = <PreStart2Content />;
  } else if (statement === 'step 3') {
    content = <ZoomContent />;
  } else if (statement === 'step 4') {
    content = <SliderScaleContent />;
  } else if (statement === 'step 5') {
    content = <OrderingContent />;
  } else if (statement === 'step 6') {
    content = <PreStart3Content />;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setAnswer('');
    // eslint-disable-next-line
  }, []);

  return (
    <CommonFade fadeIn={true}>
      <Box>{content}</Box>
    </CommonFade>
  );
}

import { Component } from 'react';
import { Typography } from '@mui/material';

import CommonButton from '../fields/CommonButton';
import GridContainer from '../layout/GridContainer';
import GridItem from '../layout/GridItem';
import PeopleImage from '../images/PeopleImage';
import ScreenContext from '../screen/ScreenContext';
import StandardScreen from '../screen/StandardScreen';
import RESOURCES from '../../../i18n/Resources';

import APP from '../../model/App';
import { LOGGER } from '../../../util/Logging';

/**
 * Error boundary to display errors that occur during rendering
 */
class ErrorBoundary extends Component {
  /**
   * @constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      screenContext: new ScreenContext(null),
    };
  }

  /**
   * Invoked when an error occurs
   * @param {Error} error The error that was raised
   */
  componentDidCatch(error) {
    LOGGER.error('An error occurred during page rendering', error);
    this.setState({ hasError: true });
  }

  /**
   * Renders the error page
   * @returns The error page contents
   */
  render() {
    const theme = APP.getTheme();

    if (this.state.hasError) {
      return (
        <StandardScreen screenContext={this.screenContext} boxSx={{ pt: 12 }}>
          <GridContainer>
            <GridItem>
              <Typography color={theme.palette.error.main}>
                {RESOURCES.get('text.unexpectedError')}
              </Typography>
            </GridItem>
            <GridItem>
              <PeopleImage sx={{ width: 350, my: 2 }} />
            </GridItem>
            <GridItem>
              <CommonButton onClick={() => window.location.reload()}>
                {RESOURCES.get('text.refresh')}
              </CommonButton>
            </GridItem>
          </GridContainer>
        </StandardScreen>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

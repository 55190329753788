import { useEffect, useState } from 'react';

import CommonTask from './CommonTask';
import GridItem from '../../components/layout/GridItem';
import SelectionList from './components/SelectionList';
import getSelectionItems from './components/SelectionItem';

/**
 * A multi-selection task
 * @param {object} props Component properties
 * @returns The selection task content
 */
export default function MultiSelectionTask(props) {
  const { answer, setAnswer, subtask } = props;
  const [selected, setSelected] = useState(answer ? answer : []);
  const [assetCount, setAssetCount] = useState(0);

  // Collect the items to display
  const { items, targetAssetCount } = getSelectionItems(
    subtask,
    selected,
    setSelected,
    assetCount,
    setAssetCount,
    true,
    null,
    true,
  );

  useEffect(() => {
    // Update the selected answer
    setAnswer(selected ? selected : []);
    // eslint-disable-next-line
  }, [selected]);

  // Render the items
  const renderedItems = [];
  for (let i = 0; i < items.length; i++) {
    renderedItems.push(items[i]());
  }

  return (
    <CommonTask
      assetCount={assetCount}
      targetAssetCount={targetAssetCount}
      subtask={subtask}
    >
      <GridItem sx={{ mt: 2 }}>
        <SelectionList>{renderedItems}</SelectionList>
      </GridItem>
    </CommonTask>
  );
}

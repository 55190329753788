import { IMaskMixin } from 'react-imask';

import TextField from '@mui/material/TextField';

/**
 * Wrapper that provides masked input for a text field
 * @param props Component properties
 * @returns The masked text field content
 */
export const CommonMaskedTextField = IMaskMixin((props) => {
  const { ...other } = props;
  return <CommonTextField {...other} />;
});

/**
 * Common text field used throughout the application
 * @param props Component properties
 * @returns The text field content
 */
export default function CommonTextField(props) {
  const { sx, ...other } = props;

  return (
    <TextField
      sx={{
        width: '35ch',
        variant: 'outlined',
        ...sx,
      }}
      {...other}
    />
  );
}

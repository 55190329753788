import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import CONFIG from '../config/Config';
import RESOURCES from '../i18n/Resources';
import { LOGGER } from './Logging';

/**
 * A Firebase error
 */
export class FirebaseError extends Error {
  /**
   * Constructor
   * @param {string} message A message
   * @param {Error} error The original error
   */
  constructor(message, error) {
    super(message);
    this._error = error;
  }

  /**
   * Returns the original error
   * @returns The original error
   */
  getError() {
    return this._error;
  }
}

/**
 * Error thrown when a user was not able to be found
 */
export class UserNotFoundError extends FirebaseError {}

/**
 * Attempts to translate the specified Firebase exception to a
 * user-appropriate error message. The error is translated (if possible)
 * and then re-thrown.
 * @param {error} e The error
 */
const translateAndThrow = (e) => {
  let errorClass = Error;
  if (e.code === 'auth/user-not-found') {
    errorClass = UserNotFoundError;
  }

  let message = RESOURCES.get(`firebase.error.${e.code}`);
  if (!message) {
    message = RESOURCES.get('unknown-error');
    // Only log if we don't recognize the error
    LOGGER.error(message, e);
  }
  throw new errorClass(message);
};

/**
 * Wrapper for Firebase authentication
 */
class Auth {
  /**
   * @constructor
   * @param {Firebase} fb The firebase wrapper object
   */
  constructor(fb) {
    // The firebase wrapper object
    this.fb = fb;
    // The firebase authentication object
    this.auth = null;
    // An optional auth callback (when auth state changes)
    this.authCallback = null;
    // Whether the authentication callback is enabled
    this.authCallbackEnabled = true;
  }

  /**
   * Sets the auth state change callback. This callback will be invoked
   * when auth state changes.
   * @param {callback} callback The auth state change callback
   */
  _setAuthCallback(callback) {
    this.authCallback = callback;
  }

  /**
   * Sets whether the authentication callback is enabled
   * @param {boolean} val Whether the authentication callback should be enabled
   */
  setAuthCallbackEnabled(val) {
    this.authCallbackEnabled = val;
  }

  /**
   * Returns the firebase authentication object
   * @returns {Auth} The firebase authentication object
   */
  _getAuth() {
    if (!this.auth) {
      const app = this.fb._getApp();
      this.auth = getAuth(app);

      if (this.authCallback) {
        onAuthStateChanged(this.auth, (user) => {
          LOGGER.trace('Auth state changed.');
          LOGGER.trace(user);
          if (this.authCallbackEnabled) {
            this.authCallback();
          }
        });
      }
    }
    return this.auth;
  }

  /**
   * Attempts to login to Firebase. Broken into a separate method to
   * allow for unit test mocking.
   * @async
   * @param {string} email User email address
   * @param {string} pass User password
   */
  async _login(email, pass) {
    const auth = this._getAuth();
    await signInWithEmailAndPassword(auth, email, pass);
  }

  /**
   * Attempts to login to Firebase
   * @async
   * @param {string} email User email address
   * @param {string} pass User password
   */
  async login(email, pass) {
    try {
      await this._login(email, pass);
    } catch (e) {
      translateAndThrow(e);
    }
  }

  /**
   * Creates the specified user
   * @async
   * @param {string} email The user email address
   * @param {string} password The user password
   */
  async createUser(email, password) {
    try {
      const auth = this._getAuth();
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (e) {
      translateAndThrow(e);
    }
  }

  /**
   * Attempts to reset a user's password
   * @async
   * @param {string} email A user's email address
   */
  async resetPassword(email) {
    try {
      const auth = this._getAuth();
      await sendPasswordResetEmail(auth, email);
    } catch (e) {
      translateAndThrow(e);
    }
  }

  /**
   * Attempts to logout from Firebase
   * @async
   */
  async logout() {
    const auth = this._getAuth();
    if (auth.currentUser) {
      await signOut(auth);
    }
  }

  /**
   * Returns the current user's access token (or null)
   * @async
   * @returns The current user's access token (or null)
   */
  async getIdToken() {
    const auth = this._getAuth();
    if (auth.currentUser) {
      return await auth.currentUser.getIdToken();
    }
    return null;
  }
}

/**
 * Wrapper for Firebase storage
 */
class Storage {
  /**
   * @constructor
   */
  constructor(fb) {
    // The firebase wrapper object
    this.fb = fb;
    // The firebase storage object
    this.storage = null;
  }

  /**
   * Returns the firebase storage object
   * @returns {FirebaseStorage} The firebase storage object
   */
  #getStorage() {
    if (!this.storage) {
      const app = this.fb._getApp();
      this.storage = getStorage(app);
    }
    return this.storage;
  }

  /**
   * Returns the download URL for the specified storage URI
   * @async
   * @param {string} uri The storage uri
   * @returns {string} The download URL for the specified storage URI
   */
  async getDownloadUrl(uri) {
    try {
      const storage = this.#getStorage();
      const r = ref(storage, uri);
      return await getDownloadURL(r);
    } catch (e) {
      LOGGER.error(e);
      translateAndThrow(e);
    }
  }
}

/**
 * Wrapper for Firebase
 */
class Firebase {
  constructor() {
    // The auth wrapper
    this.auth = new Auth(this);
    // The storage wrapper
    this.storage = new Storage(this);
    // The firebase app object
    this.app = null;
  }

  /**
   * Returns the firebase app object
   * @returns {FirebaseApp} The firebase app object
   */
  _getApp() {
    if (!this.app) throw new Error(RESOURCES.get('firebase.not_initialized'));
    return this.app;
  }

  /**
   * Returns the auth wrapper
   * @returns The auth wrapper
   */
  getAuth() {
    return this.auth;
  }

  /**
   * Returns the storage wrapper
   * @returns The storage wrapper
   */
  getStorage() {
    return this.storage;
  }

  /**
   * Initializes the firebase wrapper
   * @param {callback} authCallback Callback that will be invoked when auth
   *    state changes.
   */
  init(authCallback) {
    const config = CONFIG.getFirebaseConfig();
    this.app = initializeApp(config);
    if (authCallback) {
      this.auth._setAuthCallback(authCallback);
    }
  }
}

// Singleton
const FIREBASE = new Firebase();
// Provides access to the underlying class for unit tests, etc.
FIREBASE._class = Firebase;
// Provides access to the underlying class for unit tests, etc.
FIREBASE._authClass = Auth;

export default FIREBASE;

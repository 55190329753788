import Grid from '@mui/material/Grid';

/**
 * Items used withing grid-based layouts
 * @returns Grid item content
 */
export default function GridItem(props) {
  const { sx, xs, ...other } = props;

  return (
    <Grid
      item
      xs={xs ? xs : 1}
      sx={{
        // border: '1px solid blue',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    />
  );
}

import * as React from 'react';

import Box from '@mui/material/Box';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import StandardScreenHeaderMenu from './StandardScreenHeaderMenu';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { SCREEN_NAMES } from '../../Screens';

import CommonAvatar from '../misc/CommonAvatar';

import APP from '../../model/App';
import PROFILE from '../../model/Profile';
import RESOURCES from '../../../i18n/Resources';
import USER from '../../model/User';

import { LightTheme, DarkTheme } from '../../theme/Theme';

/**
 * Header for the standard screen
 * @param {object} props Component properties
 * @returns The header content
 */
export default function StandardScreenHeader(props) {
  const { headerContent } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const loggedIn = USER.isLoggedIn();
  let title = props.title;
  if (!title) {
    title = RESOURCES.get('text.project1138');
  }

  const isDarkTheme = APP.getTheme() === DarkTheme;

  const iconSize = {
    width: {
      xs: 20,
      sm: 32,
    },
    height: {
      xs: 20,
      sm: 32,
    },
    fontSize: {
      xs: 12,
      sm: 18,
    },
  };

  return (
    <Toolbar disableGutters={true}>
      {loggedIn && (
        <>
          <StandardScreenHeaderMenu
            open={Boolean(menuAnchorEl)}
            anchorEl={menuAnchorEl}
            handleClose={() => {
              setMenuAnchorEl(null);
            }}
          />
          <IconButton
            color="inherit"
            edge="start"
            sx={{
              ...iconSize,
              mr: 2,
              ml: {
                xs: 1.5,
                sm: 1,
              },
            }}
            onClick={(e) => {
              setMenuAnchorEl(e.currentTarget);
            }}
          >
            <MenuIcon />
          </IconButton>
        </>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          flexGrow: 1,
          marginTop: headerContent ? 1 : 0,
          marginLeft: loggedIn ? 6 : 8,
        }}
      >
        <Grid direction="column" container>
          <Grid item>
            <Typography
              sx={{
                fontFamily: 'Orbitron',
                fontSize: {
                  xs: headerContent ? '1em' : '1.3em',
                  sm: headerContent ? '1.3em' : '1.6em',
                },
              }}
              // variant={ headerContent ? 'h6' : 'h5'}
              align="center"
              noWrap
            >
              {title}
            </Typography>
          </Grid>
          {headerContent && (
            <Grid item>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ marginTop: 1 }}
              >
                {headerContent}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Tooltip
        title={RESOURCES.get(
          'text.' + (isDarkTheme ? 'lightMode' : 'darkMode'),
        )}
      >
        <IconButton
          sx={{ ml: 1, mr: loggedIn ? 0 : 1 }}
          onClick={() => {
            APP.setTheme(APP.getTheme() === DarkTheme ? LightTheme : DarkTheme);
          }}
        >
          {isDarkTheme ? (
            <LightModeIcon sx={{ ...iconSize }} />
          ) : (
            <DarkModeIcon sx={{ ...iconSize }} />
          )}
        </IconButton>
      </Tooltip>
      {loggedIn && (
        // TODO: Busy screen when loading profile, etc.
        <Tooltip title={RESOURCES.get('text.profile')}>
          <IconButton
            sx={{ mr: 1, ml: 0.25 }}
            onClick={async () => {
              PROFILE.setProfileForEditing(await PROFILE.getUserProfile());
              APP.setScreen(SCREEN_NAMES.PROFILE);
            }}
          >
            <CommonAvatar
              src={PROFILE.getUserProfileImageUrl()}
              sx={{ ...iconSize }}
            ></CommonAvatar>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

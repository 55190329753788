import Stack from '@mui/material/Stack';

/**
 * Container for buttons in screen footers
 * @param {object} props Component properties
 * @returns The container content
 */
export default function FooterButtonSet(props) {
  return (
    <Stack
      direction="row"
      spacing={3}
      justifyContent="center"
      alignItems="center"
      sx={{ p: 2, mt: 0.5 }}
    >
      {props.children}
    </Stack>
  );
}

import { useEffect } from 'react';

import Box from '@mui/material/Box';
import CommonFade from '../../components/misc/CommonFade';
import TeamSightResultsComponent from '../../components/teamsight/TeamSightResultsComponent';

/**
 * Displays the TeamSight results
 * @returns The TeamSight results content
 */
export default function TeamSightResultsTask(props) {
  const { setAnswer } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    setAnswer('');
    // eslint-disable-next-line
  }, []);

  return (
    <CommonFade fadeIn={true}>
      <Box>
        <TeamSightResultsComponent />
      </Box>
    </CommonFade>
  );
}

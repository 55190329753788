import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import CommonTask from './CommonTask';
import FirebaseAvatar from '../../components/misc/FirebaseAvatar';
import GridItem from '../../components/layout/GridItem';

import { LOGGER } from '../../../util/Logging';
import APP from '../../model/App';

// Threshold to force left alignment on small screen sizes
const LEFT_ALIGN_THRESHOLD = 20;

/**
 * An option for the Likert task
 * @param {object} props Component properties
 * @returns An option for the Likert task
 */
function AnswerOption(props) {
  const { count, onClick, optValue, range, text, value, maxTextLength } = props;
  let outputText = text.trim();
  if (outputText.length > 0) {
    outputText = `${outputText[0].toUpperCase()}${outputText.substring(1)}`;
  }

  const minOpacity = 0.1;
  const opRange = 1 - minOpacity;
  const increment = opRange / range;
  const distance = Math.abs(optValue - value);
  const opacity = minOpacity + (opRange - distance * increment);

  // TODO: This is a hack at the moment in an attempt to make the buttons more
  // clearly associated with the slider. More work to be done...
  let background = {};
  try {
    const theme = APP.getTheme();
    const hover = theme.palette.action.hover;
    const parts = hover.split(', ');

    let r = parseInt(parts[0].substring(parts[0].indexOf('(') + 1));
    let g = parseInt(parts[1]);
    let b = parseInt(parts[2]);
    //let op = parseFloat(parts[3].substring(0, parts[3].length -1));

    background = { backgroundColor: `rgb(${r}, ${g}, ${b}, .25)` };
  } catch (e) {
    LOGGER.error('Error determining colors', e);
  }

  return (
    <Grid item sx={{ width: `calc(100%/${count})`, padding: 1, paddingTop: 1 }}>
      <CardActionArea sx={{ height: '100%' }} onClick={onClick}>
        <Card
          sx={{
            height: '100%',
            opacity: opacity,
            '@media (hover: hover)': {
              '&:hover': {
                opacity: 1,
              },
            },
            ...background,
          }}
        >
          <CardContent sx={{ paddingX: 1 }}>
            <Typography
              variant="body2"
              color="text.primary"
              sx={{
                // wordBreak: 'break-word',
                hyphens: 'auto',
                textAlign:
                  maxTextLength > LEFT_ALIGN_THRESHOLD
                    ? {
                        xs: 'left',
                        sm: 'center',
                      }
                    : null,
                fontSize: {
                  xs: '1.05em',
                  sm: '1.1em',
                  md: '1.15em',
                },
                /*fontSize: '.75rem'*/
              }}
            >
              {outputText}
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </Grid>
  );
}

/**
 * An asset image associated with an option
 * @param {object} props Component properties
 * @returns An asset image
 */
function Asset(props) {
  const { asset, assetCount, setAssetCount, count, sx, ...other } = props;

  return (
    <Grid item sx={{ width: `calc(100%/${count})`, padding: 1, paddingTop: 1 }}>
      {asset && asset.length > 0 && (
        <FirebaseAvatar
          onLoad={() => {
            setAssetCount(assetCount + 1);
          }}
          hoverScale={'1.1'}
          sx={{ width: '100%', height: 140, ...sx }}
          src={asset}
          variant="rounded"
          {...other}
        />
      )}
    </Grid>
  );
}

/**
 * A Likert task
 * @param {object} props Component properties
 * @returns The Likert task content
 */
export default function LikertTask(props) {
  const { answer, setAnswer } = props;
  const [value, setValue] = useState(0);
  const [assetCount, setAssetCount] = useState(0);

  const { subtask } = props;

  const min = subtask.answer_indices[0];
  const max = subtask.answer_indices[1];

  const marks = [];
  const answerOptions = [];
  const options = subtask.answer_options;
  const count = options.length;

  useEffect(() => {
    // Set the initial answer
    setAnswer(answer ? answer : 0);
    setValue(answer ? answer : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Determine marks
  if (count > 2) {
    const range = max - min;
    const increment = range / (count - 1);
    let pos = min;
    for (let i = 0; i < count - 2; i++) {
      pos += increment;
      marks.push({ value: pos });
    }
  }

  // Determine max text length
  let maxTextLength = 0;
  if (options.length > 2) {
    for (let i = 0; i < options.length; i++) {
      const len = options[i].length;
      if (len > maxTextLength) {
        maxTextLength = len;
      }
    }
  }

  // Add options
  for (let i = 0; i < options.length; i++) {
    let optValue = 0;
    if (i === 0) {
      optValue = min;
    } else if (i === options.length - 1) {
      optValue = max;
    } else {
      optValue = marks[i - 1].value;
    }

    answerOptions.push(
      <AnswerOption
        value={value}
        optValue={optValue}
        range={Math.abs(min - max)}
        key={optValue}
        onClick={() => {
          setValue(optValue);
          setAnswer(optValue);
        }}
        count={count}
        text={options[i]}
        maxTextLength={maxTextLength}
      />,
    );
  }

  let assetList = null;
  if (subtask.assets && subtask.assets.length > 0) {
    const assets = subtask.assets;
    let count = 0;
    for (let i = 0; i < assets.length; i++) {
      if (assets[i]) count++;
    }

    if (count > 0) {
      assetList = [];
      for (let i = 0; i < assets.length; i++) {
        const asset = assets[i];
        if (asset) {
          assetList.push(
            <Asset
              asset={asset}
              count={count}
              key={`asset-${i}`}
              assetCount={assetCount}
              setAssetCount={setAssetCount}
            />,
          );
        }
      }
    }
  }

  return (
    <CommonTask
      assetCount={assetCount}
      targetAssetCount={assetList ? assetList.length : 0}
      subtask={subtask}
    >
      <GridItem>
        <Box sx={{ maxWidth: '100%', pt: 1.5 }}>
          {assetList && (
            <Grid direction="row" container sx={{ mb: 1 }}>
              {assetList}
            </Grid>
          )}
          <Slider
            sx={{ width: '75%' }}
            marks={marks}
            min={min}
            max={max}
            step={1}
            value={value}
            onChange={(e, val) => {
              setValue(val);
            }}
            onChangeCommitted={(e, val) => {
              // Update the current answer
              setAnswer(val);
            }}
          />
          <Grid direction="row" container>
            {answerOptions}
          </Grid>
        </Box>
      </GridItem>
    </CommonTask>
  );
}

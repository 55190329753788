import FIREBASE from '../../util/Firebase';
import { LOGGER } from '../../util/Logging';
import BaseModel from './BaseModel';

import PROFILE, { EMPTY_PROFILE } from './Profile';

/**
 * Represents the currently logged in user
 */
class User extends BaseModel {
  /**
   * @constructor
   */
  constructor() {
    super();
    // The login setter (from App component)
    this._setLoggedIn = null;
    // Whether the user is logged in
    this._loggedIn = false;
    // A list of callbacks waiting for a valid token
    this._tokenWaiters = [];
    // A list of listeners for auth change events
    this._authChangeListeners = [];
    // The last token read
    this._lastToken = this.INVALID_TOKEN;
  }

  INVALID_TOKEN = '__INVALID__';

  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);

    // Register an auth changed callback as part of init. It will check the
    // state of the auth token and fire on any available waiters.
    FIREBASE.init(async () => {
      // Force update of auth token
      const token = await this.getAuthToken(true);

      // When a change occurs, notify all waiters, regardless of whether
      // the token is valid or not.
      this._fireTokenWaiters(token);
    });
  }

  /**
   * Returns whether the user is logged in
   * @returns Whether the user is logged in
   */
  isLoggedIn() {
    return this._loggedIn;
  }

  /**
   * Performs login to Firebase
   * @async
   * @param {string} email User email address
   * @param {string} password User password
   */
  async login(email, password) {
    await FIREBASE.getAuth().login(email, password);

    // Force update of auth token
    await this.getAuthToken(true);
  }

  /**
   * Attempts to reset the password for the specified email address
   * @async
   * @param {string} email The email address
   */
  async resetPassword(email) {
    this.showStatus();
    try {
      await FIREBASE.getAuth().resetPassword(email);
    } finally {
      this.hideStatus();
    }
  }

  /**
   * Creates a new user
   * @param {string} email User email address
   * @param {string} password User password
   * @param {string} name User name
   */
  async createUser(email, password, name) {
    const auth = FIREBASE.getAuth();
    this.showStatus();
    try {
      auth.setAuthCallbackEnabled(false);
      await auth.createUser(email, password);
      try {
        await PROFILE.updateUserProfile(null, { ...EMPTY_PROFILE, name: name });
      } catch (e) {
        LOGGER.error('Error updating user profile', e);
      }

      // Force logout
      setTimeout(async () => {
        await auth.logout();
      }, 0);
    } finally {
      auth.setAuthCallbackEnabled(true);
      this.hideStatus();
    }
  }

  /**
   * Performs logout from Firebase
   * @async
   */
  async logout() {
    await FIREBASE.getAuth().logout();
    // Force update of auth token
    this._lastToken = this.INVALID_TOKEN;
    await this.getAuthToken(true);
  }

  /**
   * Returns the current authentication token (or null)
   * @async
   * @param notify Whether to notify registered listeners
   * @returns {string} The curent authentication token or null
   */
  async getAuthToken(notify = false) {
    const token = await FIREBASE.getAuth().getIdToken();

    if (token === this._lastToken) {
      LOGGER.trace('Token has not changed.');
      return this._lastToken;
    }
    this._lastToken = token;

    LOGGER.trace(`Token: ${token}`);

    // Notify listeners
    if (notify) {
      for (let i = 0; i < this._authChangeListeners.length; i++) {
        this._authChangeListeners[i].onAuthChangeStart();
      }
      for (let i = 0; i < this._authChangeListeners.length; i++) {
        await this._authChangeListeners[i].onAuthChange(token);
      }
    }

    if (!token) {
      this._setLoggedIn(false);
    } else {
      this._setLoggedIn(true);
      this._fireTokenWaiters(token);
    }
    return token;
  }

  /**
   * Waits for a valid token for the user and invokes the callback. If the specified
   * timeout is reached, null will be sent to the callback.
   * @param {callback} callback Invoked when a token is resolved or timeout occurs (null)
   * @param {number} timeout The timeout (in milliseconds)
   */
  waitForToken(callback, timeout) {
    const { _tokenWaiters } = this;

    const addCallback = async () => {
      // Check if an auth token is available
      const token = await this.getAuthToken();

      if (token) {
        // Invoke callback directly
        callback(token);
      } else {
        // Register a timeout to invoke the callback
        const timeoutId = setTimeout(async () => {
          this._removeTokenWaiter(callback);
          callback(await this.getAuthToken());
        }, timeout);

        // Register with waiters
        _tokenWaiters.push([callback, timeoutId]);
        this._traceWaiters();
      }
    };
    addCallback();
  }

  /**
   * Adds an auth change listener
   * @param {object} listener The auth change listener
   */
  addAuthChangeListener(listener) {
    this._authChangeListeners.push(listener);
  }

  /**
   * Fires the specified token to all of the existing token waiters
   * and subsequntly removes them from the waiting list.
   * @param {string} token
   */
  _fireTokenWaiters(token) {
    const { _tokenWaiters } = this;

    for (let i = 0; i < _tokenWaiters.length; i++) {
      const waiter = _tokenWaiters[i];
      const waiterCb = waiter[0];
      const waiterTimeoutId = waiter[1];

      clearTimeout(waiterTimeoutId);
      waiterCb(token);
    }
    // Clear the array
    this._tokenWaiters = [];

    this._traceWaiters();
  }

  /**
   * Removes the specified waiter callback from the list of token waiters.
   * @param {callback} callback The callback to remove
   */
  _removeTokenWaiter(callback) {
    const { _tokenWaiters } = this;

    let i = _tokenWaiters.length;
    while (i--) {
      const waiter = _tokenWaiters[i];
      const waiterCb = waiter[0];
      const waiterTimeoutId = waiter[1];

      if (callback === waiterCb) {
        _tokenWaiters.splice(i, 1);
        clearTimeout(waiterTimeoutId);
      }
    }
    this._traceWaiters();
  }

  /**
   * Traces information about the current token waiters
   */
  _traceWaiters() {
    const { _tokenWaiters } = this;

    LOGGER.trace('Token waiters:');
    LOGGER.trace(_tokenWaiters);
  }
}

// Singleton
const USER = new User();

export default USER;

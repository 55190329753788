import { createTheme } from '@mui/material/styles';

// The light theme
export const LightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#fafafa',
    },
  },
});

// The dark theme
export const DarkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

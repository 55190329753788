import * as React from 'react';

import Box from '@mui/material/Box';
import Image from '../../../components/misc/Image';
import Typography from '@mui/material/Typography';

import GridContainer from '../../../components/layout/GridContainer';
import GridItem from '../../../components/layout/GridItem';

/**
 * A component to display and image and related text
 * @param {object} props The component properties
 * @returns A component to display and image and related text
 */
export default function IntroComponent(props) {
  const { imageSrc, imageBorder, lines, title } = props;

  const border = !(imageBorder === false);

  const linesOutput = [];
  for (let i = 0; i < lines.length; i++) {
    linesOutput.push(
      <Typography
        key={`para-${i}`}
        sx={{
          mt: i === 0 ? 1 : null,
          mb: i !== lines.length - 1 ? 2 : null,
          color: 'text.secondary',
        }}
      >
        {lines[i]}
      </Typography>,
    );
  }

  return (
    <GridContainer>
      <GridItem>
        <Typography variant="h5" color="text.secondary">
          {title}
        </Typography>
      </GridItem>
      <GridItem>
        <Image
          src={imageSrc}
          sx={{
            width: '100%',
            maxWidth: '500px',
            borderRadius: 2,
            border: border ? '1px solid #222' : null,
          }}
        />
      </GridItem>
      <GridItem sx={{ mx: 1 }}>
        <Box>{linesOutput}</Box>
      </GridItem>
    </GridContainer>
  );
}

import { useEffect } from 'react';

import Box from '@mui/material/Box';
import CommonFade from '../../components/misc/CommonFade';
import IntroComponent from './components/IntroComponent';

import outroImage1Src from '../../../assets/images/outroImage1.jpg';
import outroImage2Src from '../../../assets/images/outroImage2.jpg';

/**
 * Outro page 1
 */
const Post1Content = (props) => {
  return (
    <IntroComponent
      title={'Results'}
      imageSrc={outroImage1Src}
      imageBorder={false}
      lines={[
        'Thank you for taking the time to answer the questions.',
        'You will soon be presented with a visual and written analysis of your professional traits categorized into 4 scales  ("Ideas", "Relational", "Action", and "Order"). ' +
          'The results are presented via a common language that can be used to better understand yourself and improve your communication with coworkers.',
      ]}
    />
  );
};

/**
 * Outro page 2
 */
const Post2Content = (props) => {
  return (
    <IntroComponent
      title={'Results'}
      imageSrc={outroImage2Src}
      imageBorder={false}
      lines={[
        'There are no right or wrong placements within the results scales, rather they provide insights into the professional traits of you and your coworkers, enabling the optimization of your collective strengths, while minimizing blind spots.',
        'Following the results, there are three short questions for providing feedback regarding the results accuracy and overall experience.',
      ]}
    />
  );
};

/**
 * Displays the post questions task
 * @returns The post questions content
 */
export default function PostQuestionsTask(props) {
  const { setAnswer, subtask } = props;
  const statement = subtask.statement;

  let content = null;
  if (statement === 'step 1') {
    content = <Post1Content />;
  } else if (statement === 'step 2') {
    content = <Post2Content />;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setAnswer('');
    // eslint-disable-next-line
  }, []);

  return (
    <CommonFade fadeIn={true}>
      <Box>{content}</Box>
    </CommonFade>
  );
}

import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
/**
 * Used to validate screen fields
 */
export default class Validator {
  // Whether the validator is enabled
  enabled = false;
  // The list of current errors
  errors = [];

  /**
   * Updates the errors to reflect the specified field information.
   * @param {string} field The field to update
   * @param {boolean} valid Whether the field if valid
   */
  _updateErrors(field, valid) {
    let found = false;
    for (let i = 0; i < this.errors.length; i++) {
      const e = this.errors[i];
      if (e.field === field) {
        found = true;
        if (valid) {
          this.errors.splice(i, 1);
        }
        break;
      }
    }
    if (!valid && !found) {
      this.errors.push({
        field: field,
      });
    }
  }

  /**
   * Checks if the field specified satisfies the minimum length requirement.
   * If not, it is added to the list of current errors.
   * @param {string} field The field name
   * @param {string} str The failed value
   * @param {number} minLen The minimum length
   * @returns Whether the field satisfies the minimum length
   */
  checkMinLength(field, str, minLen = 1) {
    const valid = str !== undefined && str.trim().length >= minLen;
    this._updateErrors(field, valid);
    return valid;
  }

  /**
   * Returns whether the specified email address is valid
   * If not, it is added to the list of current errors.
   * @param {string} field The field name
   * @param {string} email The email address
   * @returns Whether the specified email address is valid
   */
  checkValidEmail(field, email) {
    const valid = isEmail(email);
    this._updateErrors(field, valid);
    return valid;
  }

  /**
   * Returns whether the specified URL is a valid Profile URL
   * If not, it is added to the list of current errors.
   * @param {string} field The field name
   * @param {string} url The URL
   * @param {boolean} isRequired Whether the URL is required
   * @returns Whether the specified URL is a valid Profile URL
   */
  checkValidUrl(field, url, isRequired = true) {
    url = url.trim();
    const valid = (!isRequired && url.length === 0) || isURL(url);
    this._updateErrors(field, valid);
    return valid;
  }

  /**
   * Resets the current errors
   */
  reset() {
    this.errors = [];
  }

  /**
   * Whether all fields are valid
   * @returns Whether all fields are valid
   */
  isValidForAll() {
    if (!this.enabled) {
      return true;
    }

    return this.errors.length === 0;
  }

  /**
   * Returns whether the specified field is valid
   * @param {string} field The field name
   * @returns Whether the specified field is valid
   */
  isValid(field) {
    if (!this.enabled) {
      return true;
    }

    for (let i = 0; i < this.errors.length; i++) {
      const e = this.errors[i];
      if (e.field === field) {
        return false;
      }
    }
    return true;
  }

  /**
   * Whether the validator is enabled. When the validator is not enabled
   * it will collect errors, but report everything as valid.
   * @param {boolean} enabled Whether to enable the validator
   */
  setEnabled(enabled) {
    this.enabled = enabled;
  }

  /**
   * Returns whether the validator is enabled
   * @returns Whether the validator is enabled
   */
  isEnabled() {
    return this.enabled;
  }
}

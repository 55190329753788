/**
 * Tracks survey response metadata
 */
export default class Metadata {
  /**
   * @constructor
   */
  constructor() {
    this.startTime = Date.now();
    this.answers = [];
  }

  /**
   * Adds the specified answer to the metadata
   * @param {object} answer The answer
   */
  addAnswer(answer) {
    const NOW = Date.now();
    this.endTime = NOW;

    // Initial value of null (ignore)
    // if (this.answers.length === 0 && answer === null) {
    //   return;
    // }

    if (this.answers.length > 0) {
      const prevAnswer = this.answers[this.answers.length - 1].answer;
      // Answer has not changed
      if (answer === prevAnswer) {
        return;
      }

      // Check for answer not changing (arrays)
      if (Array.isArray(answer) && Array.isArray(prevAnswer)) {
        if (JSON.stringify(answer) === JSON.stringify(prevAnswer)) {
          return;
        }
      }
    }

    // Add to list of answers
    this.answers.push({
      time: NOW,
      answer: answer,
    });
  }

  /**
   * Returns the metadata in a form to post
   * @returns The metadata in a form to post
   */
  getMetadata() {
    return {
      start_time: this.startTime,
      end_time: this.endTime,
      answers: this.answers,
    };
  }
}

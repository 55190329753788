import { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';

import CommonTask from './CommonTask';
import GridItem from '../../components/layout/GridItem';

import RESOURCES from '../../../i18n/Resources';

// Maximum text length
const MAX_LENGTH = 250;

/**
 * A free text task
 * @param {object} props Component properties
 * @returns The selection task content
 */
export default function FreeTextTask(props) {
  const { answer, setAnswer, subtask } = props;
  const [text, setText] = useState(answer ? answer : '');

  const remaining = MAX_LENGTH - text.length;
  const color = remaining >= 0 ? (remaining < 20 ? 'yellow' : '') : 'red';

  useEffect(() => {
    setAnswer(remaining >= 0 && text.length > 0 ? text : null);
    // eslint-disable-next-line
  }, [remaining, text]);

  return (
    <CommonTask subtask={subtask}>
      <GridItem sx={{ mt: 1.5 }}>
        <TextField
          variant="outlined"
          label={RESOURCES.get('text.answer')}
          sx={{ width: '100%', maxWidth: 500 }}
          required
          multiline
          rows={4}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          error={remaining < 0}
          FormHelperTextProps={{
            color: color,
            sx: { textAlign: 'right', color: color, fontSize: '90%' },
          }}
          helperText={`${remaining}`}
        />
      </GridItem>
    </CommonTask>
  );
}

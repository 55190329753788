import { useEffect, useState } from 'react';
import { useForceUpdate } from '../Util';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CommonButton from '../components/fields/CommonButton';
import CommonFade from '../components/misc/CommonFade';
import CommonLink from '../components/misc/CommonLink';
import CommonPasswordField from '../components/fields/CommonPasswordField';
import CommonTextField from '../components/fields/CommonTextField';
import GridContainer from '../components/layout/GridContainer';
import GridItem from '../components/layout/GridItem';
import ScreenContext from '../components/screen/ScreenContext';
import StandardScreen from '../components/screen/StandardScreen';
import PeopleImage from '../components/images/PeopleImage';

import APP from '../model/App';
import { LOGGER } from '../../util/Logging';
import RESOURCES from '../../i18n/Resources';
import STRING_UTIL from '../../util/StringUtil';
import USER from '../model/User';

// The minimum password length
const MIN_PASSWORD_LEN = 6;

/**
 * Renders the register screen
 * @returns The register screen content
 */
export default function RegisterScreen() {
  // The screen context
  const [screenContext] = useState(new ScreenContext(useForceUpdate()));
  // Field validator
  const { validator } = screenContext;

  // Email address
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(true);

  useEffect(() => {
    // TODO: the check methods should probably return true if the
    // state has changed. That would be the indicator to force a
    // refresh.
    validator.checkValidEmail('email', email);
    validator.checkMinLength('name', name);
    validator.checkMinLength('password', password, MIN_PASSWORD_LEN);
    validator.checkMinLength(
      'confirmPassword',
      confirmPassword,
      MIN_PASSWORD_LEN,
    );

    screenContext.forceRefresh();
  }, [confirmPassword, email, name, password, screenContext, validator]);

  const isEmailValid = validator.isValid('email');
  const isNameValid = validator.isValid('name');
  const isPasswordValid = validator.isValid('password');
  const passwordLengthHelper = STRING_UTIL.format(
    RESOURCES.get('text.passwordLengthRequired'),
    ['' + MIN_PASSWORD_LEN],
  );

  let isConfirmValid = validator.isValid('confirmPassword');
  let confirmHelperMessage = null;
  if (isConfirmValid) {
    isConfirmValid = !validator.isEnabled() || password === confirmPassword;
    if (!isConfirmValid) {
      confirmHelperMessage = RESOURCES.get('text.passwordsMustMatch');
    }
  } else {
    confirmHelperMessage = passwordLengthHelper;
  }

  /**
   * Whether the form is valid
   * @returns Whether the form is valid
   */
  const isFormValid = () => {
    return validator.isValidForAll() && password === confirmPassword;
  };

  /**
   * Performs the registration operation
   */
  const onSubmit = () => {
    setSubmitEnabled(false);
    setTimeout(async () => {
      try {
        await USER.createUser(email, password, name);
        APP.showSuccessMessage(RESOURCES.get('text.registerSucceeded'));
        APP.setScreen(null);
      } catch (e) {
        setSubmitEnabled(true);
        APP.showErrorMessage(RESOURCES.get('error.register.failed'));
        LOGGER.error('An error occurred during registration', e);
      }
    }, 0);
  };

  return (
    <StandardScreen screenContext={screenContext} boxSx={{ pt: 12 }}>
      <CommonFade fadeIn={true}>
        <Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // onSubmit();
            }}
          >
            <GridContainer>
              <GridItem>
                <Typography variant="h5" color="text.secondary">
                  {RESOURCES.get('text.register')}
                </Typography>
              </GridItem>
              <GridItem>
                <Typography color="text.secondary">
                  {RESOURCES.get('text.registerDetails')}
                </Typography>
              </GridItem>
              <GridItem>
                <CommonTextField
                  sx={{ mt: 2.5 }}
                  required
                  label={RESOURCES.get('text.email')}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  error={!isEmailValid}
                  helperText={
                    !isEmailValid ? RESOURCES.get('text.emailRequired') : null
                  }
                />
              </GridItem>
              <GridItem>
                <CommonTextField
                  required
                  label={RESOURCES.get('text.name')}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  error={!isNameValid}
                  helperText={
                    !isNameValid ? RESOURCES.get('text.nameRequired') : null
                  }
                />
              </GridItem>
              <GridItem>
                <CommonPasswordField
                  required
                  label={RESOURCES.get('text.password')}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                  error={!isPasswordValid}
                  helperText={!isPasswordValid ? passwordLengthHelper : null}
                />
              </GridItem>
              <GridItem>
                <CommonPasswordField
                  required
                  label={RESOURCES.get('text.confirm')}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  value={confirmPassword}
                  error={!isConfirmValid}
                  helperText={confirmHelperMessage}
                />
              </GridItem>
              <GridItem>
                <CommonButton
                  type="submit"
                  sx={{ m: 2 }}
                  disabled={
                    !submitEnabled || (validator.isEnabled() && !isFormValid())
                  }
                  onClick={() => {
                    validator.setEnabled(true);
                    if (!isFormValid()) {
                      screenContext.forceRefresh();
                      return;
                    }
                    onSubmit();
                  }}
                >
                  {RESOURCES.get('text.register')}
                </CommonButton>
              </GridItem>
              <GridItem>
                <CommonLink
                  onClick={() => {
                    APP.setScreen(null);
                  }}
                >
                  {RESOURCES.get('text.returnToLogin')}
                </CommonLink>
              </GridItem>
              <GridItem>
                <PeopleImage />
              </GridItem>
            </GridContainer>
          </form>
        </Box>
      </CommonFade>
    </StandardScreen>
  );
}

import { useEffect, useState } from 'react';

import CommonTask from './CommonTask';
import GridItem from '../../components/layout/GridItem';
import SelectionList from './components/SelectionList';
import getSelectionItems from './components/SelectionItem';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

/**
 * An ordering task
 * @param {object} props Component properties
 * @returns The ordering task content
 */
export default function OrderingTask(props) {
  const { answer, setAnswer, subtask } = props;
  const [selected, setSelected] = useState(null);
  const [assetCount, setAssetCount] = useState(0);
  const [order, setOrder] = useState(answer);

  // Collect the items to display
  const { items, itemsOrder, targetAssetCount } = getSelectionItems(
    subtask,
    selected,
    setSelected,
    assetCount,
    setAssetCount,
    false,
    order,
  );

  // Determine the current order
  const currentOrder = order ? order : itemsOrder;

  useEffect(() => {
    // set the initial answer
    setAnswer(currentOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    // Simple re-order method
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    // Calculate the new order
    const newOrder = reorder(
      currentOrder,
      result.source.index,
      result.destination.index,
    );
    // Update the answer
    setAnswer(newOrder);
    // Update the order locally
    setOrder(newOrder);
  };

  const updatedItems = [];
  for (let i = 0; i < items.length; i++) {
    const id = `item-${i}`;
    updatedItems.push(
      <Draggable key={id} draggableId={id} index={i}>
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {items[i](snapshot.isDragging)}
            </div>
          );
        }}
      </Draggable>,
    );
  }

  return (
    <CommonTask
      assetCount={assetCount}
      targetAssetCount={targetAssetCount}
      subtask={subtask}
    >
      <GridItem sx={{ mt: 2 }}>
        <SelectionList>
          <DragDropContext
            onDragEnd={(e) => {
              onDragEnd(e);
            }}
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {updatedItems}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </SelectionList>
      </GridItem>
    </CommonTask>
  );
}

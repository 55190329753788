import LoginScreen from './screens/LoginScreen';
import ProfileScreen from './screens/ProfileScreen';
import SurveyScreen from './screens/SurveyScreen';
import RegisterScreen from './screens/RegisterScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import ViewResultsScreen from './screens/ViewResultsScreen';

import APP from './model/App';
import PROFILE from './model/Profile';
import HomeScreen from './screens/HomeScreen';

const SCREEN_NAMES = {
  PROFILE: 'profile',
  REGISTER: 'register',
  RESET_PASSWORD: 'reset-password',
  SURVEY: 'survey',
  VIEW_RESULTS: 'view-results',
};

/**
 * Renders all application screens
 * @param {object} props Properties for the display
 * @returns Content for all of the application screens
 */
function Screens(props) {
  const { loggedIn } = props;

  const screen = APP.getScreen();

  let output = null;
  if (!loggedIn) {
    if (!screen) {
      output = <LoginScreen />;
    } else {
      if (screen === SCREEN_NAMES.RESET_PASSWORD) {
        output = <ResetPasswordScreen />;
      } else if (screen === SCREEN_NAMES.REGISTER) {
        output = <RegisterScreen />;
      }
    }
  } else {
    if (!screen) {
      output = <HomeScreen />;
    } else {
      if (screen === SCREEN_NAMES.PROFILE) {
        output = <ProfileScreen profile={PROFILE.getProfileForEditing()} />;
      } else if (screen === SCREEN_NAMES.SURVEY) {
        output = <SurveyScreen />;
      } else if (screen === SCREEN_NAMES.VIEW_RESULTS) {
        output = <ViewResultsScreen />;
      }
    }
  }

  return output;
}

export { Screens, SCREEN_NAMES };

import CommonButton from '../../fields/CommonButton';

/**
 * Button used in screen footers
 * @param {object} props Component properties
 * @returns The button content
 */
export default function FooterButton(props) {
  const { sx, ...other } = props;

  return (
    <CommonButton
      sx={{
        ...sx,
        width: 160,
      }}
      {...other}
    >
      {props.children}
    </CommonButton>
  );
}

/**
 * String-related utility methods
 */
class StringUtil {
  /**
   * Allows for string-based formatting (similar to printf, etc.)
   * <code>STRING_UTIL.format("foo {0} baz {1}", ["bar", "ugh"]);</code>
   * @param {string} str The format string
   * @param {Array} o Array of replacement values
   * @returns The formatted string
   */
  format(str, o) {
    return str.replace(/{([^{}]*)}/g, function (a, b) {
      const r = o[b];
      return typeof r === 'string' ? r : a;
    });
  }
}

// The singleton
const STRING_UTIL = new StringUtil();

export default STRING_UTIL;

import * as React from 'react';

import { useState, useRef } from 'react';
import { useForceUpdate } from '../Util';

import Box from '@mui/material/Box';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import FooterButton from '../components/screen/footer/FooterButton';
import FooterButtonSet from '../components/screen/footer/FooterButtonSet';
import Metadata from '../model/Metadata';
import ScreenContext from '../components/screen/ScreenContext';
import StandardScreen from '../components/screen/StandardScreen';

import FreeTextTask from './survey-tasks/FreeTextTask';
import LikertTask from './survey-tasks/LikertTask';
import MultiSelectionTask from './survey-tasks/MultiSelectionTask';
import OrderingTask from './survey-tasks/OrderingTask';
import PostQuestionsTask from './survey-tasks/PostQuestionTask';
import PreQuestionsTask from './survey-tasks/PreQuestionTask';
import SelectionTask from './survey-tasks/SelectionTask';
import TeamSightResultsTask from './survey-tasks/TeamSightResultsTask';

import APP from '../model/App';
import SURVEY from '../model/Survey';
import RESOURCES from '../../i18n/Resources';
import { LOGGER } from '../../util/Logging';

/**
 * A linear progress bar with an associated label (percentage)
 * @param {object} props Component properties
 * @returns The progress bar content
 */
function LinearProgressWithLabel(props) {
  const { counts } = props;

  let value = 0;
  if (counts) {
    const unanswered = counts.unanswered;
    const count = counts.count;
    const answered = count - unanswered;
    value = (answered / count) * 100;
  }

  return (
    // TODO: Fix hard-coded styles (white, padding, etc.)
    <Box
      sx={{
        display: 'flex',
        width: '40vw',
        maxWidth: 200,
        paddingBottom: 1.5,
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%', mr: 1.5 }}>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: 4,
            borderRadius: 4,
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: 'primary.light',
            },
          }}
        />
      </Box>
      <Box>
        <Typography variant="body2">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

/**
 * The survey screen (administer surveys)
 * @param {object} props Component properties
 * @returns The survey screen content
 */
export default function SurveyScreen() {
  // The screen context
  const [screenContext] = useState(new ScreenContext(useForceUpdate()));
  const [subtask, setSubtask] = useState(SURVEY.getSubtask());
  const [answer, setAnswer] = useState(null);
  const [metadata, setMetadata] = useState(new Metadata());
  const [count, setCount] = useState(0);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const timeoutId = useRef(-1);
  const { type } = subtask;

  const multiSelect = type === 'selection' && subtask.options?.select > 1;

  LOGGER.trace('Current subtask:');
  LOGGER.trace(subtask);
  LOGGER.trace(`Current type: ${type}`);
  LOGGER.trace('Current answer:');
  LOGGER.trace(answer);

  // TODO: Check for known types and display message, etc.
  const taskId = `id-${count}`;

  const resetAnswers = () => {
    setAnswer(null);
    setMetadata(new Metadata());
  };

  /**
   * Submits the current answer
   */
  const submitAnswer = () => {
    APP.showBusyScreen();
    setSubmitEnabled(false);

    setTimeout(async () => {
      try {
        const nextSubtask = await SURVEY.submitResult(null, answer, metadata);
        if (nextSubtask) {
          // Clear current answers
          resetAnswers();
          // Set the next subtask
          setSubtask(nextSubtask);
          // Assign the next task identifier
          setCount(count + 1);
        } else {
          // Back to home screen
          APP.setScreen(null);
        }
      } catch (e) {
        LOGGER.error('Error submitting result', e);
        APP.showErrorMessage(RESOURCES.get('error.survey.submitAnswer'));
      } finally {
        setSubmitEnabled(true);
        APP.hideBusyScreen();
      }
    }, 0);
  };

  /**
   * Attempts to move back to the previous task
   */
  const moveBack = async () => {
    try {
      // Reset model to previous subtask
      const [prevAnswer, prevMetadata] = SURVEY.resetToPreviousSubtask();
      // Clear current answers
      resetAnswers();
      // Set the answer
      setAnswer(prevAnswer);
      // Set the metadata
      setMetadata(prevMetadata ? prevMetadata : new Metadata());
      // Set the next subtask
      setSubtask(SURVEY.getSubtask());
      // Assign the next task identifier
      setCount(count + 1);
    } catch (e) {
      LOGGER.error('Error moving back', e);
      APP.showErrorMessage(RESOURCES.get('error.survey.moveBack'));
    }
  };

  const footer = (
    <FooterButtonSet>
      <FooterButton disabled={!SURVEY.getPreviousSubtask()} onClick={moveBack}>
        {RESOURCES.get('text.back')}
      </FooterButton>
      <FooterButton
        disabled={!submitEnabled || answer === null}
        onClick={submitAnswer}
      >
        {RESOURCES.get('text.next')}
      </FooterButton>
    </FooterButtonSet>
  );

  /**
   * Updates the answer
   * @param {object} The answer
   */
  function updateAnswer(a) {
    setAnswer(a);
    // Track metadata
    metadata.addAnswer(a);
  }

  const boxSx = { pt: 14 };
  if (type === 'ts_results') {
    boxSx.maxWidth = 950;
  }

  return (
    <StandardScreen
      boxSx={boxSx}
      screenContext={screenContext}
      title={RESOURCES.get('text.survey')}
      footer={footer}
      headerContent={
        <LinearProgressWithLabel counts={SURVEY.getSurveyCounts()} />
      }
    >
      {type === 'selection' && !multiSelect && (
        <SelectionTask
          key={taskId}
          subtask={subtask}
          answer={answer}
          setAnswer={updateAnswer}
        />
      )}
      {type === 'selection' && multiSelect && (
        <MultiSelectionTask
          key={taskId}
          subtask={subtask}
          answer={answer}
          setAnswer={updateAnswer}
        />
      )}
      {type === 'likert' && (
        <LikertTask
          key={taskId}
          subtask={subtask}
          answer={answer}
          setAnswer={updateAnswer}
        />
      )}
      {type === 'ordering' && (
        <OrderingTask
          key={taskId}
          subtask={subtask}
          answer={answer}
          setAnswer={updateAnswer}
        />
      )}
      {type === 'free_text' && (
        <FreeTextTask
          key={taskId}
          subtask={subtask}
          answer={answer}
          setAnswer={(a) => {
            setAnswer(a);
            if (timeoutId.current !== -1) {
              clearTimeout(timeoutId.current);
            }
            timeoutId.current = setTimeout(() => {
              metadata.addAnswer(a);
            }, 2000 /* Record answer update if delay of 2 seconds */);
          }}
        />
      )}
      {type === 'ts_results' && (
        <TeamSightResultsTask
          key={taskId}
          subtask={subtask}
          setAnswer={setAnswer}
        />
      )}
      {type === 'pre_questions' && (
        <PreQuestionsTask
          key={taskId}
          subtask={subtask}
          setAnswer={setAnswer}
        />
      )}
      {type === 'post_questions' && (
        <PostQuestionsTask
          key={taskId}
          subtask={subtask}
          setAnswer={setAnswer}
        />
      )}
    </StandardScreen>
  );
}

import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import CommonFade from '../components/misc/CommonFade';
import backgroundSrc from '../../assets/images/SplashBackground-11.jpg';

import RESOURCES from '../../i18n/Resources';

export default function SplashScreen(props) {
  return (
    <>
      <Backdrop
        open={true}
        sx={{
          backgroundImage: `url(${backgroundSrc})`,
          backgroundColor: 'rgba(0,0,0)',
          backgroundSize: 'cover',
          filter: 'opacity(50%)',
        }}
      ></Backdrop>
      <Modal open={true} disableAutoFocus={true}>
        <Backdrop
          open={true}
          sx={{
            backgroundColor: 'rgba(0,0,0,0.0)',
            backgroundSize: 'cover',
          }}
        >
          <CommonFade fadeIn={true}>
            <Grid
              sx={{ textAlign: 'center' }}
              container
              direction="column"
              alignContent="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 40,
                      sm: 60,
                      md: 70,
                      lg: 80,
                    },
                    fontFamily: 'Orbitron',
                    color: '#FFF',
                    textShadow: '6px 6px 6px rgba(0, 0, 0, .8)',
                  }}
                >
                  {RESOURCES.get('text.project1138')}
                </Typography>
              </Grid>
            </Grid>
          </CommonFade>
        </Backdrop>
      </Modal>
    </>
  );
}

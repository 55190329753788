import Fade from '@mui/material/Fade';

/**
 * Common fade component
 * @param {object} props Component properties
 * @returns The fade content
 */
export default function CommonFade(props) {
  const { fadeIn, enterTimeout, children } = props;
  return (
    <Fade in={fadeIn} timeout={{ enter: enterTimeout ? enterTimeout : 1000 }}>
      {children}
    </Fade>
  );
}

import { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import * as React from 'react';

import Box from '@mui/material/Box';
import CommonFade from '../../components/misc/CommonFade';
import Typography from '@mui/material/Typography';

import FirebaseImage from '../../components/misc/FirebaseImage';
import GridContainer from '../../components/layout/GridContainer';
import GridItem from '../../components/layout/GridItem';

/**
 * Common wrapper for survey tasks
 * @param {object} props Component properties
 * @returns The common survey wrapper content (header, etc.)
 */
export default function CommonTask(props) {
  const { assetCount, targetAssetCount, subtask } = props;
  const [headerLoaded, setHeaderLoaded] = useState(false);
  const [forceVisible, setForceVisible] = useState(false);

  // Header image
  let headerImage = null;
  if (subtask.state_assets.length > 0) {
    // TODO: Is there ever more than one image?
    headerImage = subtask.state_assets[0];
  }

  // Statement
  let statement = subtask.statement;

  // Determine assets target
  let assetsTarget = 0;
  if (headerImage) {
    assetsTarget++;
  }
  if (targetAssetCount) {
    assetsTarget += targetAssetCount;
  }

  // Determine assets loaded
  let assetsLoaded = 0;
  if (headerLoaded) {
    assetsLoaded++;
  }
  if (assetCount) {
    assetsLoaded += assetCount;
  }

  // Set a timeout as a backstop if assets are never loaded
  useEffect(() => {
    if (!forceVisible && assetsLoaded !== assetsTarget) {
      setTimeout(() => {
        setForceVisible(true);
      }, 1000); // Wait up to a second for the images to load
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const visible = forceVisible || assetsLoaded === assetsTarget;

  let sx = {};
  if (useMediaQuery('@media (max-width:415px)')) {
    sx = {
      width: '100%',
      objectFit: 'cover',
    };
  }

  return (
    <CommonFade fadeIn={visible}>
      <Box>
        <GridContainer>
          {headerImage && (
            <GridItem>
              <FirebaseImage
                src={headerImage}
                onLoad={() => {
                  setHeaderLoaded(true);
                }}
                sx={{
                  ...sx,
                  height: 200,
                  maxWidth: '100%',
                  borderRadius: 2,
                }}
              />
            </GridItem>
          )}
          {statement && (
            <GridItem sx={{ mx: 1 }}>
              <Box>
                <Typography
                  sx={{
                    textAlign: statement.length > 128 ? 'left' : 'center',
                    color: 'text.secondary',
                  }}
                >
                  {statement}
                </Typography>
              </Box>
            </GridItem>
          )}
          {props.children}
        </GridContainer>
      </Box>
    </CommonFade>
  );
}

import { line } from 'billboard.js';

// static configurations for chart style
const chartConfig = {
  bindto: '#chart',
  point: {
    focus: {
      expand: {
        enabled: true,
        r: 20,
      },
    },
    r: 13,
    sensitivity: 30,
  },
  axis: {
    x: {
      show: false,
      type: 'category',
    },
    y: {
      show: false,
      max: 100,
      min: 0,
    },
  },
  legend: {
    show: false,
  },
  data: {
    columns: [],
    type: line(),
  },
  grid: {
    lines: { front: false },
    x: {
      show: false,
      lines: [
        { value: -0.498, class: 'bb-x idea' },
        { value: 0.5, class: 'bb-x relational' },
        { value: 1.5, class: 'bb-x action' },
        { value: 2.5, class: 'bb-x order' },
      ],
    },
    y: {
      show: false,
      lines: [
        {
          value: 100,
          text: 'Abstract',
          position: 'start',
          class: 'bb-header idea fs-8 font-weight-bold',
        },
        {
          value: 0,
          text: 'Concrete',
          position: 'start',
          class: 'bb-header-bottom idea fs-8 font-weight-bold',
        },
        {
          value: 100,
          text: 'Empathetic',
          position: 'start',
          class: 'bb-header relational fs-8 font-weight-bold',
        },
        {
          value: 0,
          text: 'Insulated',
          position: 'start',
          class: 'bb-header-bottom relational fs-8 font-weight-bold',
        },
        {
          value: 100,
          text: 'Assertive',
          position: 'start',
          class: 'bb-header action fs-8 font-weight-bold',
        },
        {
          value: 0,
          text: 'Unifying',
          position: 'start',
          class: 'bb-header-bottom action fs-8 font-weight-bold',
        },
        {
          value: 100,
          text: 'Routine',
          position: 'start',
          class: 'bb-header order fs-8 font-weight-bold',
        },
        {
          value: 0,
          text: 'Flexible',
          position: 'start',
          class: 'bb-header-bottom order fs-8 font-weight-bold',
        },
      ],
    },
  },
  tooltip: {
    grouped: false,
    show: true,
    onshown: function () {
      setTimeout(() => {
        const tip = document.getElementsByClassName('bb-tooltip-container')[0];
        const tsTip = document.getElementById('tsTooltip');
        const parent = document.getElementById('chart');
        const style = window.getComputedStyle(tip, null);

        tsTip.style.left = style.getPropertyValue('left');
        tsTip.style.top = style.getPropertyValue('top');

        // TODO: This is pretty hacky at this point
        // Does not consider bounds based on width
        // The parent.offsetLeft is not correct, but will work for the
        // current way it is being displayed.
        const bounding = tip.getBoundingClientRect();
        const parentBounding = parent.getBoundingClientRect();
        if (bounding.x <= 10) {
          const newPos = -parentBounding.x + parent.offsetLeft + 'px';
          tsTip.style.left = newPos;
        }
        setTimeout(() => {
          //tip.style.opacity = 1;
          tsTip.style.opacity = 1;
        }, 0);
      }, 0);
    },
    onhide: function () {
      // const tip = document.getElementsByClassName('bb-tooltip-container')[0];
      const tsTip = document.getElementById('tsTooltip');
      //tip.style.opacity = 0;
      tsTip.style.opacity = 0;
    },
  },
};

export default chartConfig;

export const configColors = [
  '#5D7AF9',
  '#00ABB9',
  '#B91319',
  '#58AB40',
  '#533176',
  '#F78D1E',
  '#00744D',
  '#1E66A4',
  '#F26122',
  '#00868B',
  '#743237',
  '#152C76',
  '#9C2057',
  '#004C16',
  '#B73724',
  '#FFA360',
  '#92DC3C',
  '#D33E00',
  '#002E46',
  '#FFC55B',
  '#005E82',
  '#00ABB9',
  '#B91319',
  '#58AB40',
  '#533176',
  '#F78D1E',
  '#00744D',
  '#1E66A4',
  '#F26122',
  '#00868B',
  '#743237',
  '#152C76',
  '#9C2057',
  '#004C16',
  '#B73724',
  '#FFA360',
  '#92DC3C',
  '#D33E00',
  '#002E46',
  '#FFC55B',
  '#005E82',
  '#00ABB9',
  '#B91319',
  '#58AB40',
  '#533176',
  '#F78D1E',
  '#00744D',
  '#1E66A4',
  '#F26122',
  '#00868B',
  '#743237',
  '#152C76',
  '#9C2057',
  '#004C16',
  '#B73724',
  '#FFA360',
  '#92DC3C',
  '#D33E00',
  '#002E46',
  '#FFC55B',
  '#005E82',
];

export function percentileToScale(percentile) {
  if (percentile >= 86) return 5;
  if (percentile >= 66) return 4;
  if (percentile >= 36) return 3;
  if (percentile >= 16) return 2;
  return 1;
}

import { useEffect, useState } from 'react';
import { useForceUpdate } from '../Util';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CommonButton from '../components/fields/CommonButton';
import CommonFade from '../components/misc/CommonFade';
import CommonLink from '../components/misc/CommonLink';
import CommonTextField from '../components/fields/CommonTextField';
import GridContainer from '../components/layout/GridContainer';
import GridItem from '../components/layout/GridItem';
import ScreenContext from '../components/screen/ScreenContext';
import StandardScreen from '../components/screen/StandardScreen';
import PeopleImage from '../components/images/PeopleImage';

import APP from '../model/App';
import RESOURCES from '../../i18n/Resources';
import USER from '../model/User';
import { LOGGER } from '../../util/Logging';
import { UserNotFoundError } from '../../util/Firebase';
import { isForceResetPassword } from '../Util';

/**
 * Renders the reset password screen
 * @returns The reset password screen content
 */
export default function ResetPasswordScreen() {
  // The screen context
  const [screenContext] = useState(new ScreenContext(useForceUpdate()));
  // Field validator
  const { validator } = screenContext;

  const [email, setEmail] = useState('');
  const [submitEnabled, setSubmitEnabled] = useState(true);

  if (isForceResetPassword()) {
    // Remove the request parameter
    window.history.pushState('', '', '.');
  }

  useEffect(() => {
    // TODO: the check methods should probably return true if the
    // state has changed. That would be the indicator to force a
    // refresh.
    validator.checkValidEmail('email', email);
    screenContext.forceRefresh();
  }, [email, screenContext, validator]);

  const isEmailValid = validator.isValid('email');

  /**
   * Performs the reset password operation
   */
  const onSubmit = () => {
    validator.setEnabled(true);
    if (!validator.isValidForAll()) {
      screenContext.forceRefresh();
      return;
    }

    setSubmitEnabled(false);
    setTimeout(async () => {
      let succeeded = false;
      try {
        await USER.resetPassword(email);
        succeeded = true;
      } catch (e) {
        if (UserNotFoundError.prototype.isPrototypeOf(e)) {
          // Treat user not found as success
          succeeded = true;
        } else {
          LOGGER.error('Error resetting password', e);
          APP.showErrorMessage(RESOURCES.get('error.reset.failed'));
          setSubmitEnabled(true);
          return;
        }
      } finally {
        if (succeeded) {
          APP.setScreen(null);
          APP.showSuccessMessage(RESOURCES.get('text.resetPasswordSucceeded'));
        }
      }
    }, 0);
  };

  return (
    <StandardScreen screenContext={screenContext} boxSx={{ pt: 12 }}>
      <CommonFade fadeIn={true}>
        <Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <GridContainer>
              <GridItem>
                <Typography variant="h5" color="text.secondary">
                  {RESOURCES.get('text.forgotYourPassword')}
                </Typography>
              </GridItem>
              <GridItem>
                <Typography color="text.secondary">
                  {RESOURCES.get('text.needToRegisterDetails')}
                </Typography>
              </GridItem>
              <GridItem>
                <CommonTextField
                  sx={{ mt: 2.5 }}
                  required
                  label={RESOURCES.get('text.email')}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  error={!isEmailValid}
                  helperText={
                    !isEmailValid ? RESOURCES.get('text.emailRequired') : null
                  }
                />
              </GridItem>
              <GridItem>
                <CommonButton
                  type="submit"
                  sx={{ m: 2 }}
                  disabled={!submitEnabled || !isEmailValid}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  {RESOURCES.get('text.reset')}
                </CommonButton>
              </GridItem>
              <GridItem>
                <CommonLink
                  onClick={() => {
                    APP.setScreen(null);
                  }}
                >
                  {RESOURCES.get('text.returnToLogin')}
                </CommonLink>
              </GridItem>
              <GridItem>
                <PeopleImage />
              </GridItem>
            </GridContainer>
          </form>
        </Box>
      </CommonFade>
    </StandardScreen>
  );
}

import i18n from './en.json';

/**
 * Resources used throughout the application
 */
class Resources {
  /**
   * @constructor
   */
  constructor() {
    // The active resources (current language)
    this.resources = i18n;
  }

  /**
   * Returns the string corresponding to the specified name
   * @param {string} name The name to lookup
   * @returns {string} The string corresponding to the specified name
   */
  get(name) {
    return this.resources[name];
  }
}

// Singleton
const RESOURCES = new Resources();

export default RESOURCES;

/**
 * Callback interface invoked when the authentication information changes
 */
class AuthChangeListener {
  /**
   * Invoked prior to the auth change event being fired.
   * This provides the opportunity to reset state that may be affected by
   * other listeners, etc.
   */
  onAuthChangeStart() {}

  /**
   * Invoked when the authentication information changes
   * @param {String} token The updated token (or null)
   */
  onAuthChange(token) {}
}

export default AuthChangeListener;

import { DragHandle } from '@mui/icons-material';

import Checkbox from '@mui/material/Checkbox';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import FirebaseAvatar from '../../../components/misc/FirebaseAvatar';

import RESOURCES from '../../../../i18n/Resources';
import STRING_UTIL from '../../../../util/StringUtil';

/**
 * A selection task item
 * @param {object} props Component properties
 * @returns The selection task item content
 */
export function SelectionItem(props) {
  const {
    answer, // The answer associated with the item
    onLoad, // Invoked when the image associated with the item is loaded
    option, // The item text
    image, // The image associated with the item
    selected, // The current selection
    setSelected, // Method to invoked to set the selection
    isDragging, // Whether the item is being dragged
    isDraggable, // Whether the item is draggable
    isMultiSelect, // Whether the item is part of a multi-select list
    isSelected, // Whether the item is selected
  } = props;

  // Force upper case for first character of option
  let opt = option;
  if (opt.length > 0) {
    opt = opt.charAt(0).toUpperCase() + opt.slice(1);
  }

  let sx = null;
  if (isDragging) {
    sx = {
      backgroundColor: 'action.disabled',
    };
  }

  return (
    <ListItem>
      <ListItemButton
        sx={sx}
        onClick={() => {
          if (!isMultiSelect) {
            setSelected(answer);
          } else {
            const currentIndex = selected.indexOf(answer);
            const newSelected = [...selected];
            if (currentIndex === -1) {
              newSelected.push(answer);
            } else {
              newSelected.splice(currentIndex, 1);
            }
            setSelected(newSelected);
          }
        }}
        selected={isSelected}
      >
        {isMultiSelect && (
          <ListItemIcon>
            <Checkbox checked={isSelected} tabIndex={-1} disableRipple />
          </ListItemIcon>
        )}
        {image && (
          <ListItemAvatar
            onClick={(e) => {
              e.stopPropagation();
              return false;
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              return false;
            }}
            onMouseUp={(e) => {
              e.stopPropagation();
              return false;
            }}
          >
            <FirebaseAvatar
              sx={{ width: 60, height: 60 }}
              src={image}
              onLoad={onLoad}
              variant="rounded"
            >
              &nbsp;
            </FirebaseAvatar>
          </ListItemAvatar>
        )}
        {option && (
          <ListItemText sx={{ pl: image ? 2 : 0 }}>
            <Typography variant="body2">{opt}</Typography>
          </ListItemText>
        )}
        {isDraggable && <DragHandle sx={{ ml: 2 }} />}
      </ListItemButton>
    </ListItem>
  );
}

/**
 * Returns the items to select from
 * @param {object} subtask The sub task
 * @param {object} selected The currently selected item (or array of items for multi-select)
 * @param {function} setSelected The function used to set selection
 * @param {number} assetCount The count of assets that have been loaded
 * @param {function} setAssetCount The function used to update the assets that have been loaded
 * @param {boolean} isSelectable Whether the item is selectable
 * @param {Array} orderIn (optional) The order to display the items (array of answers)
 * @param {boolean} isMultiSelect (optional) Whether multiple items can be selected
 * @returns
 */
export default function getSelectionItems(
  subtask,
  selected,
  setSelected,
  assetCount,
  setAssetCount,
  isSelectable = true,
  orderIn = null,
  isMultiSelect = false,
) {
  let order = [];
  let items = [];
  let targetAssetCount = 0;

  if (orderIn) {
    // They are ordered, create arrays to hold the results
    order = new Array(orderIn.length);
    items = new Array(orderIn.length);
  }

  let labelImages = true;
  if (subtask.answer_options) {
    for (let i = 0; i < subtask.answer_options.length; i++) {
      if (subtask.answer_options[i].trim().length > 0) {
        labelImages = false;
        break;
      }
    }
  }

  if (labelImages) subtask.answer_options = null;

  for (let i = 0; i < subtask.answer_indices.length; i++) {
    const answer = subtask.answer_indices[i];
    const option =
      subtask.answer_options && subtask.answer_options.length > i
        ? subtask.answer_options[i]
        : labelImages
        ? STRING_UTIL.format(RESOURCES.get('text.image.labeled'), [
            '' + (i + 1),
          ])
        : null;
    const image =
      subtask.assets && subtask.assets.length > i ? subtask.assets[i] : null;

    if (image) {
      targetAssetCount++;
    }

    let itemSelected = false;
    if (isSelectable) {
      if (isMultiSelect && selected) {
        for (let j = 0; j < selected.length; j++) {
          if (selected[j] === answer) {
            itemSelected = true;
            break;
          }
        }
      } else {
        itemSelected = selected === answer;
      }
    }

    // The item rendering function
    const renderItem = (isDragging) => {
      return (
        <SelectionItem
          isDraggable={!isSelectable}
          isDragging={isDragging}
          onLoad={() => {
            setAssetCount(assetCount + 1);
          }}
          key={answer}
          isSelected={itemSelected}
          selected={selected}
          answer={answer}
          option={option}
          image={image}
          setSelected={setSelected}
          isMultiSelect={isMultiSelect}
        />
      );
    };

    if (!orderIn) {
      order.push(answer);
      items.push(renderItem);
    } else {
      for (let x = 0; x < orderIn.length; x++) {
        if (orderIn[x] === answer) {
          items[x] = renderItem;
          order[x] = answer;
          break;
        }
      }
    }
  }

  return {
    items: items,
    itemsOrder: order,
    targetAssetCount: targetAssetCount,
  };
}

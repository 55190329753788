import { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';

import CommonLightbox from './CommonLightbox';

import FIREBASE from '../../../util/Firebase';
import { LOGGER } from '../../../util/Logging';

/**
 * An avatar where the image is pulled from firebase.
 * The src property points to a file in firebase storage.
 * @returns The components for the avatar
 */
export default function FirebaseAvatar(props) {
  const [imgSrc, setImgSrc] = useState('');
  const { src, sx, hoverScale, ...other } = props;
  const [lightboxOpen, setLightboxOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const s = await FIREBASE.getStorage().getDownloadUrl(src);
        setImgSrc(s);
      } catch (e) {
        LOGGER.error(e);
        setImgSrc('');
      }
    })();
  }, [src]);

  return (
    <>
      {lightboxOpen && (
        <CommonLightbox
          imgSrc={imgSrc}
          onClose={() => {
            setLightboxOpen(false);
          }}
        />
      )}
      <Avatar
        src={imgSrc}
        sx={{
          ...sx,
          cursor: 'pointer',
          '@media (hover: hover)': {
            '&:hover': {
              transform: `scale(${hoverScale ? hoverScale : '1.2'})`,
            },
          },
          transition: 'all .4s ease-in-out',
        }}
        {...other}
        onClick={() => {
          setLightboxOpen(true);
        }}
      />
    </>
  );
}

import { Lightbox } from '../3rdParty/react-modal-image';

/**
 * A common lightbox component
 * @param {object} props The component's properties
 * @returns THe lightbox elements
 */
export default function CommonLightbox(props) {
  const { onClose, imgSrc, ...other } = props;

  return (
    <Lightbox hideDownload={true} large={imgSrc} onClose={onClose} {...other} />
  );
}

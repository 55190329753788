import { CommonMaskedTextField } from './CommonTextField';

/**
 * Common phone number input field used throughout the application
 * @param props Properties for the component
 * @returns phone number field content
 */
const CommonNumberField = (props) => {
  const { ...other } = props;

  return <CommonMaskedTextField mask={Number} {...other} />;
};

export default CommonNumberField;

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

/**
 * Header screen component
 * @param {object} props Component properties
 * @returns The header content
 */
function Header(props) {
  return <AppBar sx={{ mb: 8 }}>{props.children}</AppBar>;
}

/**
 * Footer screen component
 * @param {object} props Component properties
 * @returns The footer content
 */
function Footer(props) {
  return (
    <AppBar sx={{ top: 'auto', bottom: 0 }} color="background" elevation={0}>
      {props.children}
    </AppBar>
  );
}

/**
 * A screen within the application
 * @returns Screen content
 */
export default function Screen(props) {
  const { boxSx } = props;

  const headerContent = props.header;
  const showHeader = headerContent;
  const footerContent = props.footer;
  const showFooter = footerContent;

  const header = <Header>{headerContent}</Header>;
  const footer = <Footer>{footerContent}</Footer>;

  let paddingTop = 5;
  if (showHeader) paddingTop += 5;
  let paddingBottom = 5;
  if (showFooter) paddingBottom += 5;

  return (
    <>
      {showHeader && header}
      <Box
        display="flex"
        justifyContent="center"
        sx={
          {
            // border: '1px solid purple',
          }
        }
      >
        <Box
          sx={{
            // border: '1px solid red',
            width: '100vw',
            maxWidth: 700,
            px: 2,
            pt: paddingTop,
            pb: paddingBottom,
            ...boxSx,
          }}
        >
          {props.children}
        </Box>
      </Box>
      {showFooter && footer}
    </>
  );
}

import Button from '@mui/material/Button';

/**
 * Common button used throughout the application
 * @returns Button content
 */
export default function CommonButton(props) {
  const { sx, ...other } = props;

  return (
    <Button
      variant="contained"
      sx={{
        variant: 'outlined',
        ...sx,
      }}
      {...other}
    />
  );
}

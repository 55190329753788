import AuthChangeListener from './AuthChangeListener';

import { LOGGER } from '../../util/Logging';
import USER from './User';
import BaseModel from './BaseModel';

import { START_STATES } from '../AppStartStates';

/**
 * Global application state
 */
class App extends BaseModel {
  /**
   * @constructor
   */
  constructor() {
    super();
    // Application state (init, start, etc.)
    this._startState = null;

    // Tracking previous screen
    this._previousScreen = null;
    // State for current screen
    this._screen = null;
    this._setScreen = null;

    // State for theme
    this._theme = null;
    this._setTheme = null;

    // State for message display (error, success, etc.)
    this._setMessage = null;
    this._setMessageSeverity = null;

    // State for busy screen
    this._busyScreenSetOpen = null;
    this._busyScreenSetMessage = null;
    // Busy screen tracking
    this._busyScreenCounter = 0;
    this._busyScreenMinClose = 0;
  }

  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);

    // Reset the profile any time the authentication state changes
    const authListener = new AuthChangeListener();
    authListener.onAuthChangeStart = () => {
      // Reset state
      this._reset();
    };
    authListener.onAuthChange = async (token) => {
      // Clear current screen
      if (this._setScreen) {
        this._setScreen(null);
      }
    };

    USER.addAuthChangeListener(authListener);
  }

  /**
   * Shows the busy screen
   * @param {string} message Optional busy screen message
   */
  showBusyScreen(message = null) {
    this._busyScreenSetMessage(message);

    if (this._busyScreenCounter === 0) {
      setTimeout(() => {
        if (this._busyScreenCounter !== 0) {
          if (this._startState === START_STATES.STARTED) {
            this._busyScreenSetOpen(true);
          }
          // Don't allow close for at least a half second
          this._busyScreenMinClose = Date.now() + 500;
        }
        // Wait a half second before displaying
      }, 500);
    }
    // Increment outstanding busy screens
    ++this._busyScreenCounter;
  }

  /**
   * Hides the busy screen
   */
  hideBusyScreen() {
    const close = () => {
      // Make sure the screen should be closed
      if (--this._busyScreenCounter === 0) {
        this._busyScreenSetOpen(false);
        this._busyScreenMinClose = 0;
      }
    };

    // Determine how long to keep the busy screen prior to closing
    const remaining = this._busyScreenMinClose - Date.now();
    if (remaining <= 0) {
      close();
    } else {
      setTimeout(() => {
        close();
      }, remaining);
    }
  }

  /**
   * Returns the current theme
   * @returns The current theme
   */
  getTheme() {
    return this._theme;
  }

  /**
   * Sets the current theme
   * @param {object}} theme The theme
   */
  setTheme(theme) {
    this._setTheme(theme);
  }

  /**
   * Displays a message corresponding to the specified exception
   * @param {error} exception The exception to display
   */
  showExceptionMessage(exception) {
    LOGGER.trace(exception);
    this.showErrorMessage(exception.message);
  }

  /**
   * Displays the specified error message
   * @param {string} message The error message to display
   */
  showErrorMessage(message) {
    this._setMessageSeverity('error');
    this._setMessage(message);
  }

  /**
   * Displays the specified warning message
   * @param {string} message The warning message to display
   */
  showWarningMessage(message) {
    this._setMessageSeverity('warning');
    this._setMessage(message);
  }

  /**
   * Displays the specified success message
   * @param {string} message The success message to display
   */
  showSuccessMessage(message) {
    this._setMessageSeverity('success');
    this._setMessage(message);
  }

  /**
   * Sets the current screen
   * @param {screen} screen The current screen
   */
  setScreen(screen) {
    if (screen === this._screen) return;
    this._previousScreen = this._screen;
    this._setScreen(screen);
  }

  /**
   * Returns the current screen
   * @returns The current screen
   */
  getScreen() {
    return this._screen;
  }

  /**
   * Returns the previous screen
   * @returns The previous screen
   */
  getPreviousScreen() {
    return this._previousScreen;
  }

  /**
   * Resets the state of the model
   */
  _reset() {
    this._previousScreen = null;
  }
}

// Singleton
const APP = new App();

export default APP;

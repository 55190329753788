import { useState } from 'react';
import { useForceUpdate } from '../Util';

import Box from '@mui/system/Box';
import CommonButton from '../components/fields/CommonButton';
import CommonFade from '../components/misc/CommonFade';
import Typography from '@mui/material/Typography';

import GridContainer from '../components/layout/GridContainer';
import GridItem from '../components/layout/GridItem';
import ScreenContext from '../components/screen/ScreenContext';
import StandardScreen from '../components/screen/StandardScreen';
import PeopleImage from '../components/images/PeopleImage';

import APP from '../model/App';
import PROFILE from '../model/Profile';
import RESOURCES from '../../i18n/Resources';
import SURVEY from '../model/Survey';
import TEAMSIGHT from '../model/TeamSight';
import { LOGGER } from '../../util/Logging';
import { SCREEN_NAMES } from '../Screens';

/**
 * The home screen
 * @param {object} props Component properties
 * @returns The home screen content
 */
export default function HomeScreen() {
  const [submitEnabled, setSubmitEnabled] = useState(true);

  const surveyValid = SURVEY.isValid();
  const profileValid = PROFILE.isValid();
  const valid = surveyValid && profileValid;
  const completed = valid && SURVEY.isCompleted();
  let content = null;

  if (valid) {
    if (!completed) {
      // Start/resume survey
      content = (
        <>
          <GridItem>
            <Box sx={{ px: 2 }}>
              <Typography sx={{ pb: 2, color: 'text.secondary' }}>
                {RESOURCES.get('text.home.startP1')}
              </Typography>
              <PeopleImage sx={{ p: 0, my: 2, width: 350 }} />
              {/* <Typography sx={{ pb: 2, color: 'text.secondary' }}>
                {RESOURCES.get('text.home.startP2')}
              </Typography>
              <Typography sx={{ pb: 1, color: 'text.secondary' }}>
                {RESOURCES.get('text.home.startP3')}
              </Typography> */}
            </Box>
          </GridItem>
          <GridItem>
            <CommonButton
              sx={{ mt: 1 }}
              onClick={() => {
                APP.setScreen(SCREEN_NAMES.SURVEY);
              }}
            >
              {RESOURCES.get(
                SURVEY.isInProgress()
                  ? 'text.resumeSurvey'
                  : 'text.beginSurvey',
              )}
            </CommonButton>
          </GridItem>
        </>
      );
    } else {
      // Survey completed
      content = (
        <>
          <GridItem>
            <Box sx={{ px: 2 }}>
              <Typography sx={{ pb: 1, color: 'text.secondary' }}>
                {RESOURCES.get('text.home.completedP1')}
              </Typography>
              <PeopleImage sx={{ p: 0, mt: 3, width: 350 }} />
            </Box>
          </GridItem>
          <GridItem>
            <CommonButton
              disabled={!submitEnabled || !TEAMSIGHT.isComplete()}
              sx={{ mt: 2 }}
              onClick={async () => {
                try {
                  setSubmitEnabled(false);
                  await TEAMSIGHT.calculateStats();
                  APP.setScreen(SCREEN_NAMES.VIEW_RESULTS);
                } catch (e) {
                  LOGGER.error('Error calculating stats', e);
                  APP.showErrorMessage(RESOURCES.get('error.results.loading'));
                  setSubmitEnabled(true);
                }
              }}
            >
              {RESOURCES.get('text.viewResults')}
            </CommonButton>
          </GridItem>
        </>
      );
    }
  } else {
    // Error has occurred
    content = (
      <>
        <GridItem>
          <Box sx={{ px: 2 }}>
            <Typography sx={{ pb: 2, color: 'text.secondary' }}>
              {RESOURCES.get(
                !surveyValid ? 'text.home.errorP1' : 'text.home.errorP1Profile',
              )}
            </Typography>
            <PeopleImage sx={{ p: 0, mt: 2, mb: 3, width: 350 }} />
            <Typography sx={{ pb: 1, color: 'text.secondary' }}>
              {RESOURCES.get('text.home.errorP2')}
            </Typography>
          </Box>
        </GridItem>
        <GridItem>
          <CommonButton
            disabled={!submitEnabled}
            sx={{ mt: 1 }}
            onClick={() => {
              if (!surveyValid) {
                window.location.reload();
              } else {
                setSubmitEnabled(false);
                setTimeout(async () => {
                  try {
                    await PROFILE.createEmptyProfile();
                  } catch (e) {
                    LOGGER.error('Error attempting to create empty profile', e);
                  }
                  window.location.reload();
                }, 0);
              }
            }}
          >
            {RESOURCES.get('text.refresh')}
          </CommonButton>
        </GridItem>
      </>
    );
  }

  // The screen context
  const [screenContext] = useState(new ScreenContext(useForceUpdate()));
  return (
    <StandardScreen screenContext={screenContext} boxSx={{ pt: 12 }}>
      <CommonFade fadeIn={true}>
        <Box>
          <GridContainer>{content}</GridContainer>
        </Box>
      </CommonFade>
    </StandardScreen>
  );
}

import Image from '../misc/Image';
import src from '../../../assets/images/PeopleImage.jpg';

/**
 * Renders the people image
 * @returns The people image
 */
export default function PeopleImage(props) {
  const { sx } = props;
  return (
    <Image
      src={src}
      sx={{ mt: 4, width: 500, borderRadius: 3, maxWidth: '100%', ...sx }}
    />
  );
}

import Grid from '@mui/material/Grid';

/**
 * Container for grid-based layouts
 * @returns Grid container content
 */
export default function GridContainer(props) {
  const { alignItems, sx, columns, justifyContent, rowSpacing, ...other } =
    props;

  return (
    <Grid
      container
      columns={columns ? columns : 1}
      direction="column"
      rowSpacing={rowSpacing ? rowSpacing : 2}
      justifyContent={justifyContent ? justifyContent : 'center'}
      alignItems={alignItems ? alignItems : 'stretch'}
      sx={{
        // border: '1px solid green',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    />
  );
}

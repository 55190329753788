import * as React from 'react';

// import Check from '@mui/icons-material/Check';
// import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// import { DarkTheme, LightTheme } from '../../theme/Theme';

// import APP from '../../model/App';
import RESOURCES from '../../../i18n/Resources';
import USER from '../../model/User';

import { LOGGER } from '../../../util/Logging';
// import { Divider } from '@mui/material';

/**
 * Menu used on standard screens
 * @returns The components for the menu
 */
export default function StandardScreenHeaderMenu(props) {
  const { anchorEl, open, handleClose } = props;
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      {/* <MenuItem
        onClick={() => {
          handleClose();
          APP.setTheme(APP.getTheme() === DarkTheme ? LightTheme : DarkTheme);
        }}
      >
        {APP.getTheme() === DarkTheme && (
          <ListItemIcon>
            <Check />
          </ListItemIcon>
        )}
        {RESOURCES.get('text.darkMode')}
      </MenuItem>
      <Divider /> */}
      <MenuItem
        onClick={async () => {
          try {
            await USER.logout();
          } catch (ex) {
            LOGGER.error(ex);
          } finally {
            handleClose();
          }
        }}
      >
        {RESOURCES.get('text.logout')}
      </MenuItem>
    </Menu>
  );
}

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

/**
 * Application snack bar
 * @param props Display properties
 * @returns The content of the snack bar
 */
function AppSnackbar(props) {
  const message = props.message;
  const setMessage = props.setMessage;
  const severity = props.severity;

  const onClose = () => {
    setMessage(null);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={message !== null}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity={severity ? severity : 'info'}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default AppSnackbar;

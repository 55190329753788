import Box from '@mui/material/Box';

/**
 * An image
 * @returns The components for the image
 */
export default function Image(props) {
  const { alt, sx, ...other } = props;

  return <Box component="img" sx={sx} alt={alt ? alt : ''} {...other} />;
}

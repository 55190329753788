import BaseService from './BaseService';

/**
 * Service used to access user profiles
 */
class ProfileService extends BaseService {
  /**
   * Returns the user profile for the specified token
   * @async
   * @param {String} token The token
   * @returns The user profile for the specified token
   */
  async getUserProfile(token) {
    return await this._fetchGet(token, '/profile/get_prof');
  }

  /**
   * Updates the profile
   * @async
   * @param {string} token The token
   * @param {object} profile The profile
   * @returns The updated profile information
   */
  async updateUserProfile(token, profile) {
    return await this._fetchPost(token, '/profile/update_prof', profile);
  }

  /**
   * Updates a user's profile image
   * @param {string} token The token
   * @param {File} imageFile The image file
   * @returns The updated profile information
   */
  async uploadProfileImage(token, imageFile) {
    const formData = new FormData();
    formData.append('image', imageFile);
    return await this._fetchPost(
      token,
      '/profile/upload_prof_image',
      formData,
      false,
      null,
    );
  }
}

// Singleton
const PROFILE_SERVICE = new ProfileService();

export default PROFILE_SERVICE;

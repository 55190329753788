import BaseModel from './BaseModel';

import AuthChangeListener from './AuthChangeListener';
import PROFILE from './Profile';
import SURVEY_SERVICE from '../../service/SurveyService';
import TEAMSIGHT_SERVICE from '../../service/TeamSightService';
import USER from './User';

import { LOGGER } from '../../util/Logging';

import questions from '../../assets/teamsight/TeamSightQuestions.json';
import evaluation from '../../assets/teamsight/TeamSightEvaluation.json';

/**
 * The TeamSight measurement types
 */
export const MEASUREMENT_TYPE = {
  IDEA: 0,
  RELATIONAL: 1,
  ACTION: 2,
  ORDER: 3,
};

/**
 * Model associated with the TeamSight survey
 */
class TeamSight extends BaseModel {
  /**
   * @constructor
   */
  constructor() {
    super();
    this._reset();
  }

  /** The internal survey name */
  SURVEY_NAME = '__TEAMSIGHT__';
  /** Prefix for TeamSight-based properties (in the profile) */
  TEAMSIGHT_PROPS_PREFIX = 'teamsight_';
  /** The responses property (in the profile) */
  RESPONSES_PROP = this.TEAMSIGHT_PROPS_PREFIX + 'responses';

  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);
    this._reset();

    const authListener = new AuthChangeListener();
    authListener.onAuthChangeStart = () => {
      // Reset state
      this._reset();
    };
    authListener.onAuthChange = () => {};
    USER.addAuthChangeListener(authListener);
  }

  /**
   * Returns whether the survey is the TeamSight survey
   * @param {string} survey The survey name
   * @returns Whether the survey is the TeamSight survey
   */
  isTeamSightSurvey(survey) {
    return survey === this.SURVEY_NAME;
  }

  /**
   * Returns the external name of the TeamSight survey (used by external services)
   * @returns The external name of the TeamSight survey (used by external services)
   */
  getExternalSurveyName() {
    return 'teamsight_survey';
  }

  /**
   * Returns the summary content for the specified measurement type
   * @param {number} measurementType The measurement type
   * @returns The summary content for the specified measurement type
   */
  getSummaryContent(measurementType) {
    if (!this._stats) {
      throw new Error('Stats have not been calculated');
    }

    let measurement = '';
    let scale = 0;
    switch (measurementType) {
      case MEASUREMENT_TYPE.IDEA:
        measurement = 'Idea';
        scale = this._stats.ideasScale;
        break;
      case MEASUREMENT_TYPE.ORDER:
        measurement = 'Order';
        scale = this._stats.orderScale;
        break;
      case MEASUREMENT_TYPE.RELATIONAL:
        measurement = 'Relational';
        scale = this._stats.relationalScale;
        break;
      case MEASUREMENT_TYPE.ACTION:
        measurement = 'Action';
        scale = this._stats.actionScale;
        break;
      default:
        throw new Error(`Unknown measurement type: ${measurementType}`);
    }

    for (let i = 0; i < evaluation.length; i++) {
      const curr = evaluation[i];
      if (curr.measurement === measurement && curr.scale === scale) {
        return curr['Summary Paragraph Content'];
      }
    }

    throw new Error(
      `Unable to find summary content for type: ${measurement}, ${scale}`,
    );
  }

  /**
   * Returns the current TeamSight responses for the user
   * @async
   * @param {string} token The token
   * @returns The current TeamSight responses for the user
   */
  async _getResponses(token) {
    const profile = await PROFILE.getUserProfile(token);
    let responses = profile.props?.[this.RESPONSES_PROP];
    this._responses = responses ? responses : '';

    // For testing.
    // this._responses = "";
    // for (let i = 0; i < 80; i++) {
    //   // this._responses += ((i % 2) == 0) ?  "a" : "c";
    //   this._responses += "c";
    // }

    return this._responses;
  }

  /**
   * Sets the TeamSight responses for the user
   * @async
   * @param {string} token The token
   * @param {string} responses The responses
   */
  async _setResponses(token, responses) {
    const profile = { ...(await PROFILE.getUserProfile(token)) };

    let props = {};
    if (profile.props) {
      props = profile.props;
    }

    props[this.RESPONSES_PROP] = responses;
    profile.props = props;

    await PROFILE.updateUserProfile(token, profile);
  }

  /**
   * Calculates and caches the TeamSight stats for the user
   * @async
   * @returns The TeamSight stats
   */
  async calculateStats() {
    this.showStatus();
    try {
      if (!this.isComplete()) {
        throw new Error('Unable to calculate stats, survey is not complete');
      }
      const stats = await TEAMSIGHT_SERVICE.calculateStats(this._responses);

      LOGGER.trace('Stats:');
      LOGGER.trace(stats);

      // Cache the stats
      this._stats = stats;

      return stats;
    } finally {
      this.hideStatus();
    }
  }

  /**
   * Returns the cached TeamSight stats for the user
   * @returns The cached TeamSight stats for the user
   */
  getCachedStats() {
    if (!this._stats) {
      throw new Error('Stats have not been calculated');
    }
    return this._stats;
  }

  /**
   * Returns whether the TeamSight survey is complete
   * @param {string} responses The current responses
   * @returns Whether the TeamSight survey is complete
   */
  _isComplete(responses) {
    return responses.length === questions.length;
  }

  /**
   * Returns whether the TeamSight survey is complete
   * @returns Whether the TeamSight survey is complete
   */
  isComplete() {
    return this._isComplete(this._responses);
  }

  /**
   * Returns whether the survey is complete
   * @async
   * @param {string} token The token
   * @returns Whether the survey is complete
   */
  async checkCompletion(token) {
    // Call to ensure response structure is in place
    await SURVEY_SERVICE.checkCompletion(token, this.getExternalSurveyName());
    const responses = await this._getResponses(token);
    const surveyCount = questions.length;
    const responseCount = responses.length;

    return {
      complete: this._isComplete(responses),
      survey: this.SURVEY_NAME,
      survey_ct: surveyCount,
      unanswered: surveyCount - responseCount,
    };
  }

  /**
   * Returns the next task (if available)
   * @async
   * @param {string} token The token
   * @returns The next task (if available)
   */
  async getTask(token) {
    // Rely on responses being read by check completion
    const responses = this._responses;
    if (this._isComplete(responses)) {
      return null;
    }

    const question = questions[responses.length];
    const taskId = question['Task ID'];
    const task = await TEAMSIGHT_SERVICE.getTask(token, taskId);

    LOGGER.trace('TeamSight Task:');
    LOGGER.trace(task);

    if (task.subtasks) {
      // TODO: Bug in API.. should be subtask (vs. subtasks)
      task.subtask = task.subtasks;
      delete task.subtasks;
    }

    // Reverse the answer options
    if (task.subtask?.st1?.answer_options) {
      task.subtask.st1.answer_options.reverse();
      task.subtask.st1.answer_indices.reverse();

      const options = task.subtask.st1.answer_options;
      for (let i = 0; i < options.length; i++) {
        const opt = options[i];
        if (opt === 'neutral') {
          options[i] = 'uncertain';
        }
      }
    }

    return {
      question: {
        ...task,
        st_count: 1,
        relationship: null,
        root_branch: false,
      },
      survey: this.SURVEY_NAME,
    };
  }

  /**
   * Submits the specified result
   * @async
   * @param {string} token The token
   * @param {object} result The result
   */
  async submitResponse(token, result) {
    // Find the index in the TeamSight questions
    const taskId = result.t_id;
    let index = -1;
    for (let i = 0; i < questions.length; i++) {
      const task = questions[i];
      const currentTaskId = task['Task ID'];
      if (currentTaskId === taskId) {
        index = i;
        break;
      }
    }

    if (index < 0) {
      throw Error('Unable to find corresponding task for result');
    }

    // Add the answer
    let answer = result.response.answer[0];
    let responses = this._responses;
    if (responses.length === index) {
      responses += answer;
    } else {
      responses =
        responses.substring(0, index) + answer + responses.substring(index + 1);
    }

    LOGGER.trace(`Current TeamSight responses: ${responses}`);

    await this._setResponses(token, responses);
  }

  /**
   * Resets the model
   */
  _reset() {
    this._responses = '';
    this._stats = null;
  }
}

const TEAMSIGHT = new TeamSight();

export default TEAMSIGHT;

import Validator from '../Validator';

/**
 * Context information for screens
 */
class ScreenContext {
  /**
   * @constructor
   * @param forceUpdate Force screen update hook
   */
  constructor(forceUpdate) {
    this.forceUpdate = forceUpdate;
    this.validator = new Validator();
  }

  /**
   * Validator used to validate fields
   * @returns Validator used to validate fields
   */
  getValidator() {
    return this.validator;
  }

  /**
   * Forces the page to be refreshed
   */
  forceRefresh() {
    this.forceUpdate();
  }
}

export default ScreenContext;

import AuthChangeListener from './AuthChangeListener';
import PROFILE_SERVICE from '../../service/ProfileService';
import RESOURCES from '../../i18n/Resources';
import USER from './User';

import { LOGGER } from '../../util/Logging';
import { preloadImage } from '../../util/ImageUtil';
import BaseModel from './BaseModel';

import FIREBASE from '../../util/Firebase';

/**
 * An empty set of profile values
 * (useful for cloning, etc.)
 */
export const EMPTY_PROFILE = {
  name: '',
  phone: '',
  yob: '',
  dept: '',
  title: '',
  linkedin: '',
  country: '',
  state: '',
};

/**
 * Represents the profile for the currently logged in user
 */
class Profile extends BaseModel {
  /**
   * @constructor
   */
  constructor() {
    super();
    this._reset();
  }

  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);

    // Reset the profile any time the authentication state changes
    const authListener = new AuthChangeListener();
    authListener.onAuthChangeStart = () => {
      // Reset state
      this._reset();
    };
    authListener.onAuthChange = async (token) => {
      if (token) {
        // Force initial load of profile
        try {
          LOGGER.trace('Retrieving user profile on auth change.');
          await this.getUserProfile(token);
        } catch (e) {
          LOGGER.error('Error retrieving profile on auth change', e);
        }
      }
    };

    USER.addAuthChangeListener(authListener);
  }

  /**
   * Whether the model is valid
   * @returns Whether the model is valid
   */
  isValid() {
    return this._profileValid;
  }

  /**
   * Returns the string representation of the specified number
   * @param {number} number A number
   * @returns The string representation of the specified number
   */
  _numberToString(number) {
    // eslint-disable-next-line eqeqeq
    if (number == '0') {
      return '';
    }

    return `${number}`;
  }

  /**
   * Returns the number representation of the specified string
   * @param {string} str A string
   * @returns The number representation of the specified string
   */
  _stringToNumber(str) {
    if (str.trim() === '') {
      return 0;
    }

    return parseInt(str);
  }

  /**
   * Creates an empty user profile
   * @async
   * @param {s} token The token
   */
  async createEmptyProfile(token = null) {
    this.showStatus();
    try {
      await PROFILE.updateUserProfile(
        token ? token : await USER.getAuthToken(),
        { ...EMPTY_PROFILE },
      );
    } finally {
      this.hideStatus();
    }
  }

  /**
   * Returns the profile for the currently logged in user
   * @param {string} token (optional) The user token
   * @param {boolean} forceRead (optional) Whether to force a new read
   *    versus returning a cached version.
   * @returns The profile for the currently logged in user
   */
  async getUserProfile(token = null, forceRead = false) {
    this.showStatus();
    try {
      if (!this._profile || forceRead) {
        LOGGER.trace('Retrieving user profile.');
        try {
          let profile = {
            name: '',
            phone: 0,
            yob: 0,
            dept: '',
            title: '',
            linkedin: '',
            country: '',
            state: '',
            prof_image: '',
          };

          const retrievedProfile = await PROFILE_SERVICE.getUserProfile(
            token ? token : await USER.getAuthToken(),
          );

          const clone = { ...profile, ...retrievedProfile };
          clone.phone = this._numberToString(clone.phone);
          clone.yob = this._numberToString(clone.yob);

          if (clone.prof_image) {
            try {
              this._profileImageUrl =
                await FIREBASE.getStorage().getDownloadUrl(clone.prof_image);
              this._profileImageCache = await preloadImage(
                this._profileImageUrl,
              );
            } catch (e) {
              LOGGER.error('Error retrieving profile image', e);
            }
          }

          this._profile = clone;
          this._profileValid = true;
          LOGGER.trace(this._profile);
        } catch (e) {
          LOGGER.error(e);
          throw new Error(RESOURCES.get('error.profile.fetch'));
        }
      } else {
        LOGGER.trace('Returning (cached) user profile.');
      }
      return this._profile;
    } finally {
      this.hideStatus();
    }
  }

  /**
   * Updates the user profile
   * @param {string} tokenIn The token (optional)
   * @param {object} profile The updated profile
   * @param {File} profileImageFile The profile image (optional)
   */
  async updateUserProfile(tokenIn, profile, profileImageFile) {
    this.showStatus();
    try {
      const token = tokenIn ? tokenIn : await USER.getAuthToken();
      const clone = { ...profile };
      delete clone.prof_image;

      // Clean up numerics
      clone.yob = this._stringToNumber(clone.yob);
      clone.phone = this._stringToNumber(clone.phone);

      LOGGER.trace('Updating user profile');
      LOGGER.trace(clone);

      // Update image file (if applicable)
      if (profileImageFile) {
        await PROFILE_SERVICE.uploadProfileImage(token, profileImageFile);
      }
      // Update the user profile
      await PROFILE_SERVICE.updateUserProfile(token, clone);
      // Read the latest version of the user profile
      await this.getUserProfile(token, true);
    } finally {
      this.hideStatus();
    }
  }

  /**
   * Returns the user's profile image (if available)
   * @returns The user's profile image (if available)
   */
  getUserProfileImageUrl() {
    return this._profileImageUrl;
  }

  /**
   * Sets the profile to edit (in the profile screen)
   * @param {object} profile The profile to edit
   */
  setProfileForEditing(profile) {
    LOGGER.trace(`Set profile for editing:`);
    LOGGER.trace(profile);

    this._profileForEditing = profile;
  }

  /**
   * Returns the name of the user that is logged in
   * @returns The name of the user that is logged in
   */
  getName() {
    return this._profile ? this._profile.name : null;
  }

  /**
   * Returns the profile to edit
   * @returns The profile to edit
   */
  getProfileForEditing() {
    LOGGER.trace(`Get profile for editing:`);
    LOGGER.trace(this._profileForEditing);

    return this._profileForEditing;
  }

  /**
   * Resets (clears) profile information
   */
  _reset() {
    LOGGER.trace('Resetting user profile.');

    this._profileValid = false;
    this._profile = null;
    this._profileImageUrl = null;
    this._profileForEditing = null;
    this._profileImageCache = null;
  }
}

// Singleton
const PROFILE = new Profile();

export default PROFILE;

//   {
//     "name": "Ethan",
//     "phone": 9492788450,
//     "dept": "string",
//     "title": "string",
//     "linkedin": "string",
//     "yob": 0,
//     "country": "string",
//     "state": "string",
//     "prof_image": "https://storage.cloud.google.com/n1-wethos.appspot.com/profile/KGEXdQ2fjtTLIKwYDWjUAUvim3O2/test.jpg"
// }

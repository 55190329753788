import { useEffect, useState } from 'react';
import { selectFile, toBase64, useForceUpdate } from '../Util';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CommonAvatar from '../components/misc/CommonAvatar';
import CommonFade from '../components/misc/CommonFade';
import CommonNumberField from '../components/fields/CommonNumberField';
import CommonTextField from '../components/fields/CommonTextField';
import FooterButton from '../components/screen/footer/FooterButton';
import FooterButtonSet from '../components/screen/footer/FooterButtonSet';
import GridContainer from '../components/layout/GridContainer';
import GridItem from '../components/layout/GridItem';
import ScreenContext from '../components/screen/ScreenContext';
import StandardScreen from '../components/screen/StandardScreen';

import APP from '../model/App';
import PROFILE from '../model/Profile';
import RESOURCES from '../../i18n/Resources';
import STRING_UTIL from '../../util/StringUtil';

import { LOGGER } from '../../util/Logging';

// The maximum size of images (in megabytes)
const MAX_IMAGE_SIZE_MB = 5;

/**
 * Renders the profile screen
 * @returns The profile screen content
 */
export default function ProfileScreen(props) {
  // The screen context
  const [screenContext] = useState(new ScreenContext(useForceUpdate()));
  // Field validator
  const { validator } = screenContext;
  const [profile, setProfile] = useState({ ...props.profile });
  const [profileImage, setProfileImage] = useState(
    PROFILE.getUserProfileImageUrl(),
  );
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [submitEnabled, setSubmitEnabled] = useState(true);

  useEffect(() => {
    validator.checkMinLength('name', profile.name);
    validator.checkValidUrl('linkedin', profile.linkedin, false);
    screenContext.forceRefresh();
  }, [profile, screenContext, validator]);

  const isNameValid = validator.isValid('name');
  const isLinkedInValid = validator.isValid('linkedin');
  const footer = (
    <FooterButtonSet>
      <FooterButton
        type="submit"
        disabled={!submitEnabled || !isNameValid || !isLinkedInValid}
        onClick={() => {
          onSubmit();
        }}
      >
        {RESOURCES.get('text.ok')}
      </FooterButton>
      <FooterButton
        onClick={() => {
          APP.setScreen(APP.getPreviousScreen());
        }}
      >
        {RESOURCES.get('text.cancel')}
      </FooterButton>
    </FooterButtonSet>
  );

  /**
   * Perfoms the update profile operation
   */
  const onSubmit = () => {
    setSubmitEnabled(false);
    setTimeout(async () => {
      try {
        validator.setEnabled(true);
        if (!validator.isValidForAll()) {
          screenContext.forceRefresh();
          return;
        }

        await PROFILE.updateUserProfile(null, profile, profileImageFile);
        APP.setScreen(APP.getPreviousScreen());
      } catch (e) {
        setSubmitEnabled(true);
        LOGGER.error('Error updating user profile', e);
        APP.showErrorMessage(RESOURCES.get('error.profile.update'));
      }
    }, 0);
  };

  return (
    <StandardScreen
      screenContext={screenContext}
      footer={footer}
      title={RESOURCES.get('text.profile')}
    >
      <CommonFade fadeIn={true}>
        <Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <GridContainer>
              <GridItem sx={{ mb: 1 }}>
                <IconButton
                  onClick={async () => {
                    const file = await selectFile('image/*', false);
                    if (file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
                      APP.showWarningMessage(
                        STRING_UTIL.format(
                          RESOURCES.get('text.image.exceedsMaxSize'),
                          ['' + MAX_IMAGE_SIZE_MB],
                        ),
                      );
                      return;
                    }
                    const base64 = await toBase64(file);
                    setProfileImage(base64);
                    setProfileImageFile(file);
                  }}
                >
                  <CommonAvatar
                    sx={{ width: 125, height: 125 }}
                    src={profileImage}
                    textCallback={(name) => {
                      return <Typography variant="h2">{name}</Typography>;
                    }}
                  />
                </IconButton>
              </GridItem>
              <GridItem>
                <CommonTextField
                  required
                  label={RESOURCES.get('text.name')}
                  onChange={(e) => {
                    setProfile({ ...profile, name: e.target.value });
                  }}
                  value={profile.name}
                  error={!isNameValid}
                  helperText={
                    !isNameValid ? RESOURCES.get('text.nameRequired') : null
                  }
                />
              </GridItem>
              <GridItem>
                <CommonNumberField
                  label={RESOURCES.get('text.phoneNumber')}
                  onChange={(e) => {
                    setProfile({ ...profile, phone: e.target.value });
                  }}
                  value={profile.phone}
                />
              </GridItem>
              <GridItem>
                <CommonTextField
                  label={RESOURCES.get('text.department')}
                  onChange={(e) => {
                    setProfile({ ...profile, dept: e.target.value });
                  }}
                  value={profile.dept}
                />
              </GridItem>
              <GridItem>
                <CommonTextField
                  label={RESOURCES.get('text.title')}
                  onChange={(e) => {
                    setProfile({ ...profile, title: e.target.value });
                  }}
                  value={profile.title}
                />
              </GridItem>
              <GridItem>
                <CommonTextField
                  label={RESOURCES.get('text.linkedInProfile')}
                  onChange={(e) => {
                    setProfile({ ...profile, linkedin: e.target.value });
                  }}
                  value={profile.linkedin}
                  error={!isLinkedInValid}
                  helperText={
                    !isLinkedInValid
                      ? RESOURCES.get('text.linkedInUrlRequired')
                      : null
                  }
                />
              </GridItem>
            </GridContainer>
          </form>
        </Box>
      </CommonFade>
    </StandardScreen>
  );
}

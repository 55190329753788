import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

/**
 * Common link component
 * @param {object} props Component properties
 * @returns The link content
 */
export default function CommonLink(props) {
  const { sx, ...other } = props;

  return (
    <Link component="button" underline="none" sx={sx} {...other}>
      <Typography>{props.children}</Typography>
    </Link>
  );
}

/**
 * Preloads the specified image
 * @param {string} src The source image
 * @returns The promise for loading the image
 */
export function preloadImage(src) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload =
      img.onerror =
      img.onabort =
        () => {
          resolve(img);
        };
    img.src = src;
  });
}

import AuthChangeListener from './AuthChangeListener';
import SURVEY_SERVICE from '../../service/SurveyService';

import CONFIG from '../../config/Config';
import TEAMSIGHT from './TeamSight';
import USER from './User';

import orderedQuestions from '../../assets/ordered/OrderedQuestions.json';

import { LOGGER } from '../../util/Logging';
import BaseModel from './BaseModel';

/**
 * Represents the survey being administered
 */
class Survey extends BaseModel {
  /**
   * @constructor
   */
  constructor() {
    super();
    this._reset();
  }

  /**
   * Initializes the model
   * @param statusCb The optional status callback
   */
  init(statusCb) {
    super.init(statusCb);

    const authListener = new AuthChangeListener();
    authListener.onAuthChangeStart = () => {
      // Reset state
      this._reset();
    };
    authListener.onAuthChange = async (token) => {
      if (token) {
        try {
          LOGGER.trace('Retrieving survey state.');
          await this.loadState(token);
          this._surveyValid = true;
        } catch (e) {
          LOGGER.error('Error retrieving survey state', e);
        }
      }
    };

    USER.addAuthChangeListener(authListener);
  }

  /**
   * Attempts to retrieve the current surveys
   * @async
   * @param {string} token The token
   * @returns The current surveys
   */
  async _getSurveys(token) {
    // Fetch the surveys
    const surveysResponse = await SURVEY_SERVICE.getSurveys(token);
    LOGGER.trace('Surveys response:');
    LOGGER.trace(surveysResponse);

    const surveys = [];
    if (surveysResponse.surveys) {
      if (Array.isArray(surveysResponse.surveys)) {
        const surveyArray = surveysResponse.surveys;
        if (surveyArray.length > 0) {
          const validSurverys = CONFIG.getSurveys();

          // Loop searching for valid surveys (ordered)
          for (let i = 0; i < validSurverys.length; i++) {
            const validName = validSurverys[i];

            if (TEAMSIGHT.isTeamSightSurvey(validName)) {
              surveys.push(validName);
              continue;
            }

            // Find in current response
            for (let j = 0; j < surveyArray.length; j++) {
              const current = surveyArray[j].survey;
              if (validName === current) {
                surveys.push(current);
                break;
              }
            }
          }
        }
      }
    }

    if (surveys.length === 0) {
      throw Error('No surveys found');
    }

    return surveys;
  }

  /**
   * Whether the model is valid (successfully loaded survey state)
   * @returns Whether the model is valid (successfully loaded survey state)
   */
  isValid() {
    return this._surveyValid;
  }

  /**
   * Checks whether the state is valid, if not throws an exception
   */
  _checkValid() {
    if (!this.isValid()) {
      throw new Error('Survey state is not valid');
    }
  }

  /**
   * Returns the currently active survey
   * @returns The currently active survey
   */
  _getActiveSurvey() {
    for (let i = 0; i < this._surveys.length; i++) {
      const survey = this._surveys[i];
      const curr = this._status[survey];
      if (!curr.complete) {
        return survey;
      }
    }
    return null;
  }

  /**
   * Returns the current subtask
   * @returns The current subtask
   */
  getSubtask() {
    this._checkValid();
    if (this.isCompleted()) {
      return null;
    }

    return this._subtask;
  }

  /**
   * Returns the previous subtask (if one is available)
   * @returns The previous subtask (if one is available)
   */
  getPreviousSubtask() {
    this._checkValid();

    if (this.isCompleted()) {
      return null;
    }

    return this._prevSubtask;
  }

  /**
   * Resets the state of the model back to the previous subtask
   * @returns The previous answer and metadata as array
   */
  resetToPreviousSubtask() {
    this._checkValid();

    if (!this._prevQuestion || !this._prevSubtask) {
      throw Error('A previous subtask does not exist');
    }

    this._question = this._prevQuestion;
    this._subtask = this._prevSubtask;
    this._prevQuestion = null;
    this._prevSubtask = null;

    return [this._prevAnswer, this._prevMetadata];
  }

  /**
   * Returns whether all surveys have been completed
   * @returns Whether all surveys have been completed
   */
  isCompleted() {
    this._checkValid();
    return this._getActiveSurvey() === null;
  }

  /**
   * Returns info for the first subtask for the specified subtasks
   *
   * @param subtasks The subtasks
   * @returns The first subtask
   */
  _getFirstSubtask(subtasks) {
    for (let st in subtasks) {
      const subtask = subtasks[st];
      subtask._name = st;
      return subtask;
    }

    return null;
  }

  /**
   * Attempts tor retrieve the next task
   * @async
   * @param {string} tokenIn The token
   * @returns Whether the task was retrieved
   */
  async _retrieveNextTask(tokenIn = null) {
    this.showStatus();
    try {
      const token = tokenIn ? tokenIn : await USER.getAuthToken();

      // Update survey status
      await this._updateSurveyStatus(token);

      // Determine the active survey
      const activeSurvey = this._getActiveSurvey();
      LOGGER.trace(`Active survey: ${activeSurvey}`);

      if (activeSurvey) {
        // Ordered check
        const ordered = orderedQuestions[activeSurvey];
        let taskId = null;
        if (ordered) {
          const status = this._status[activeSurvey];
          const index = status.survey_ct - status.unanswered;
          if (index >= status.survey_ct) {
            throw Error('All ordered questions have been answered.');
          }
          taskId = ordered[index]['t_id'];
          if (!taskId) {
            throw Error('Unable to find ordered task identifier.');
          }
        }

        // Attempt to retrieve the next task
        let task = TEAMSIGHT.isTeamSightSurvey(activeSurvey)
          ? await TEAMSIGHT.getTask(token)
          : taskId
          ? await SURVEY_SERVICE.getSpecificTask(token, activeSurvey, taskId)
          : await SURVEY_SERVICE.getTask(token, activeSurvey);

        // Hack to add question wrapper (for ordered)
        // This should really be fixed on the server side.
        if (taskId) {
          if (task?.subtasks?.st1?.type === 'ts_results') {
            // Make sure the TeamSight stats are available
            await TEAMSIGHT.calculateStats();
          }

          task.relationship = null;
          task.st_count = 1;
          task.root_branch = false;
          task.subtask = task.subtasks;
          task = {
            question: task,
          };
        }

        LOGGER.trace('Task:');
        LOGGER.trace(task);

        const question = task.question;
        if (question && question.subtask) {
          const subtask = this._getFirstSubtask(question.subtask);
          if (!subtask) {
            throw Error('Unable to find subtask');
          }

          LOGGER.trace('Question:');
          LOGGER.trace(question);
          LOGGER.trace('SubTask:');
          LOGGER.trace(subtask);

          if (!question || !subtask) {
            throw new Error('Unable to find next task.');
          }

          // Set the question and subtask that were retrieved
          this._question = question;
          // Track active survey with question (for use in results)
          // (we need to have the survey name)
          this._question._survey = activeSurvey;
          this._subtask = subtask;

          return true;
        }
      } else {
        LOGGER.trace('All surveys have been completed.');
        this._question = null;
        this._subtask = null;
        return false;
      }
    } finally {
      this.hideStatus();
    }
  }

  /**
   * Submits the specified result
   * @param {string} tokenIn The token (optional)
   * @param {object} answer The answer
   * @param {object} metadata The meta-data
   * @returns
   */
  async submitResult(tokenIn = null, answer, metadata) {
    this._checkValid();

    // Update the final time
    if (metadata) {
      metadata.addAnswer(answer);
    }

    const question = this._question;
    const subtask = this._subtask;
    let valid = false;

    this.showStatus();

    try {
      // Retrieve the stored survey name from the questions
      // (added when the question was initially retrieved)
      const survey = question._survey;
      const token = tokenIn ? tokenIn : await USER.getAuthToken();
      const isTeamSight = TEAMSIGHT.isTeamSightSurvey(survey);

      // Validate and throw exceptions if not valid
      if (!question) {
        throw new Error('A question is not available');
      }
      if (!survey) {
        throw new Error('A survey is not available.');
      }
      if (!subtask) {
        throw new Error('Subtask is not available');
      }

      // Prepare the result
      const result = {
        survey: isTeamSight ? TEAMSIGHT.getExternalSurveyName() : survey,
        t_id: question.t_id,
        subtask: subtask._name,
        st_count: question.st_count,
        relationship: question.relationship,
        response: {
          answer: answer,
        },
        metadata: metadata ? metadata.getMetadata() : {},
      };

      LOGGER.trace('Result being posted:');
      LOGGER.trace(result);

      // Submit the response
      const res = await SURVEY_SERVICE.submitResponse(token, result);
      LOGGER.trace('Response from result posting:');
      LOGGER.trace(res);

      // Check that the response was received
      if (res.added !== true) {
        throw Error('The response was not added.');
      }

      if (!isTeamSight) {
        // Check for subtask from response
        const resSubtasks = res.next_subtask;
        if (resSubtasks) {
          const nextSubtask = this._getFirstSubtask(resSubtasks);
          if (!nextSubtask) {
            throw Error('Unable to find subtask');
          }
          this._subtask = nextSubtask;

          // We retrieved the next subtask
          valid = true;
          return this._subtask;
        }
      } else {
        // TeamSight response handling
        await TEAMSIGHT.submitResponse(token, result);
      }

      // Attempt to find the next task
      const foundNext = await this._retrieveNextTask(token);
      if (foundNext) {
        // We found a subtask
        valid = true;
        return this._subtask;
      } else {
        // No subtasks found (end of surveys)
        return null;
      }
    } finally {
      if (valid) {
        if (
          question.relationship === 'branching' &&
          question.root_branch === true
        ) {
          this._prevQuestion = null;
          this._prevSubtask = null;
          this._prevAnswer = null;
          this._prevMetadata = null;
        } else {
          // Track previous
          this._prevQuestion = question;
          this._prevSubtask = subtask;
          this._prevAnswer = answer;
          this._prevMetadata = metadata;
        }
      }
      this.hideStatus();
    }
  }

  /**
   * Updates the survey status in the model
   * @param {string} token The token
   */
  async _updateSurveyStatus(token) {
    const surveys = this._surveys;

    let count = 0;
    let unanswered = 0;

    const status = {};
    for (let i = 0; i < surveys.length; i++) {
      const survey = surveys[i];
      const completion = TEAMSIGHT.isTeamSightSurvey(survey)
        ? await TEAMSIGHT.checkCompletion(token)
        : await SURVEY_SERVICE.checkCompletion(token, survey);

      count += completion.survey_ct;
      unanswered += completion.unanswered;
      status[survey] = completion;
    }

    LOGGER.trace('Survey status:');
    LOGGER.trace(status);
    this._status = status;

    // Track the counts
    this._surveyCounts = {
      count: count,
      unanswered: unanswered,
    };
  }

  /**
   * Returns the current survey counts
   * @returns The current survey counts
   */
  getSurveyCounts() {
    this._checkValid();
    return this._surveyCounts;
  }

  /**
   * Returns whether the surveys are in progress
   * @returns Whether the surveys are in progress
   */
  isInProgress() {
    this._checkValid();
    const counts = this._surveyCounts;
    return counts.count !== counts.unanswered;
  }

  /**
   * Attempts to load the current survey state
   * @param {string} token The token
   */
  async loadState(token) {
    // Reset current state
    this._reset();

    this.showStatus();
    try {
      // Get full list of surveys (sorted)
      const surveys = await this._getSurveys(token);
      LOGGER.trace('Surveys:');
      LOGGER.trace(surveys);
      this._surveys = surveys;

      // Retrieve the next task
      await this._retrieveNextTask(token);

      // Mark the survey state as valid
      this._surveyValid = true;
    } finally {
      this.hideStatus();

      if (!this._surveyValid) {
        this._reset();
      }
    }
  }

  /**
   * Resets the model
   */
  _reset() {
    this._surveyValid = false;
    this._surveyCounts = null;
    this._surveys = [];
    this._status = {};
    this._question = null;
    this._subtask = null;

    this._prevQuestion = null;
    this._prevSubtask = null;
    this._prevAnswer = null;
    this._prevMetadata = null;
  }
}

// Singleton
const SURVEY = new Survey();

export default SURVEY;

import BaseService from './BaseService';

/**
 * Service used to access user surveys
 */
class SurveyService extends BaseService {
  /**
   * Returns the current survey list
   * @param {string} token The token
   * @returns The current survey list
   */
  async getSurveys(token) {
    return await this._fetchGet(token, '/survey/get');
  }

  /**
   * Returns the next task for the specified survey
   * @async
   * @param {string} token The token
   * @param {string} surveyId The survey identifier
   * @returns The next task for the specified survey
   */
  async getTask(token, surveyId) {
    return await this._fetchPost(token, '/survey/get_task', {
      survey: surveyId,
    });
  }

  /**
   * Returns the requested task for the specified assessment
   * @async
   * @param {string} token The token
   * @param {string} assessment The assessment
   * @param {string} taskId The task identifier
   * @returns The next task for the specified survey
   */
  async getSpecificTask(token, assessment, taskId) {
    return await this._fetchPost(token, '/survey/get_task/spec', {
      t_id: taskId,
      assessment: assessment,
    });
  }

  /**
   * Checks whether the specified survey has been completed
   * @async
   * @param {string} token The token
   * @param {string} surveyId The survey identifier
   * @returns Whether the survey has been completed
   */
  async checkCompletion(token, surveyId) {
    return await this._fetchGet(
      token,
      `/survey/check_completion?survey=${surveyId}`,
    );
  }

  /**
   * Adds the specified response
   * @async
   * @param {string} token The token
   * @param {object} response The response
   */
  async submitResponse(token, response) {
    return await this._fetchPost(token, '/survey/response/add', response);
  }
}

// Singleton
const SURVEY_SERVICE = new SurveyService();

export default SURVEY_SERVICE;

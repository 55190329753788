import { useState } from 'react';
import { useForceUpdate } from '../Util';

import Box from '@mui/material/Box';

import CommonFade from '../components/misc/CommonFade';
import FooterButton from '../components/screen/footer/FooterButton';
import FooterButtonSet from '../components/screen/footer/FooterButtonSet';
import ScreenContext from '../components/screen/ScreenContext';
import StandardScreen from '../components/screen/StandardScreen';
import TeamSightResultsComponent from '../components/teamsight/TeamSightResultsComponent';

import APP from '../model/App';
import RESOURCES from '../../i18n/Resources';

/**
 * Screen used to view the TeamSight results
 * @param {object} props Component properties
 * @returns Components to display the TeamSight results
 */
export default function ViewResultsScreen(props) {
  // The screen context
  const [screenContext] = useState(new ScreenContext(useForceUpdate()));

  const footer = (
    <FooterButtonSet>
      <FooterButton
        onClick={() => {
          APP.setScreen(null);
        }}
      >
        {RESOURCES.get('text.close')}
      </FooterButton>
    </FooterButtonSet>
  );

  return (
    <StandardScreen
      footer={footer}
      screenContext={screenContext}
      title={RESOURCES.get('text.results')}
      boxSx={{ maxWidth: 950 }}
    >
      <CommonFade fadeIn={true}>
        <Box>
          <TeamSightResultsComponent sx={{ mt: 0 }} />
        </Box>
      </CommonFade>
    </StandardScreen>
  );
}
